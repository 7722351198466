import React, { ReactNode, useRef } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  useToast,
  ToastId,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  FiHome, 
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import useUser from '../../lib/useUser';
import { getMe, logOut } from "../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IUser } from '../../types';
import {
  NavLink as RouterLink
} from "react-router-dom";

interface LinkItemProps {
  name: string;
  url: string;
  icon: IconType;
}
const ManagerLinkItems: Array<LinkItemProps> = [
  { name: '제품정보', url: '/product', icon: FiHome,  },
  { name: 'Leave Form', url: '/leaveforms', icon: FiHome,  },
  { name: 'Request Form', url: '/samplerequests', icon: FiHome,  },
  { name: '게시판', url: '/boards', icon: FiTrendingUp },
  { name: '[Draft] 견적서', url: '/quotations_draft', icon: FiTrendingUp },
  { name: '견적서', url: '/quotations', icon: FiTrendingUp },
  { name: '계약서', url: '/salesagreements', icon: FiCompass },
  { name: '수리폼', url: '/repairs', icon: FiSettings },
];

const SalesLinkItems: Array<LinkItemProps> = [
  { name: '제품정보', url: '/product', icon: FiHome,  },
  { name: 'Leave Form', url: '/leaveforms', icon: FiHome,  },
  { name: 'Request Form', url: '/samplerequests', icon: FiHome,  },
  { name: '게시판', url: '/boards', icon: FiTrendingUp },
  { name: '[Draft] 견적서', url: '/quotations_draft', icon: FiTrendingUp },
  { name: '견적서', url: '/quotations', icon: FiTrendingUp },
  { name: '계약서', url: '/salesagreements', icon: FiCompass },
  { name: '수리폼', url: '/repairs', icon: FiSettings },
  { name: '기타등등', url: '/test', icon: FiStar },
];
const TSLinkItems: Array<LinkItemProps> = [
  { name: '제품정보', url: '/product', icon: FiHome,  },
  { name: 'Leave Form', url: '/leaveforms', icon: FiHome,  },
  { name: 'Request Form', url: '/samplerequests', icon: FiHome,  },
  { name: '게시판', url: '/boards', icon: FiTrendingUp },
  { name: '[Draft] 견적서', url: '/quotations_draft', icon: FiTrendingUp },
  { name: '수리폼', url: '/repairs', icon: FiSettings },
  { name: '기타등등', url: '/test', icon: FiStar },
];

const ManagementLinkItems: Array<LinkItemProps> = [
  { name: '제품정보', url: '/product', icon: FiHome,  },
  { name: 'Leave Form', url: '/leaveforms', icon: FiHome,  },
  { name: 'Request Form', url: '/samplerequests', icon: FiHome,  },
  { name: '게시판', url: '/boards', icon: FiTrendingUp },
  { name: '수리폼', url: '/repairs', icon: FiSettings },
  { name: '견적서', url: '/quotations', icon: FiTrendingUp },
  { name: '계약서', url: '/salesagreements', icon: FiCompass },
  // { name: '수리폼', url: '/repairs', icon: FiSettings },
  // { name: 'T', url: '/home', icon: FiStar },
];

const MarketingLinkItems: Array<LinkItemProps> = [
  { name: '제품정보', url: '/product', icon: FiHome,  },
  { name: 'Leave Form', url: '/leaveforms', icon: FiHome,  },
  { name: 'Request Form', url: '/samplerequests', icon: FiHome,  },
  { name: '[Draft] 견적서', url: '/quotations_draft', icon: FiTrendingUp },
  { name: '게시판', url: '/boards', icon: FiTrendingUp },
  // { name: '수리폼', url: '/repairs', icon: FiSettings },
  // { name: 'T', url: '/home', icon: FiStar },
];

const DigitalLinkItems: Array<LinkItemProps> = [
  { name: '제품정보', url: '/product', icon: FiHome,  },
  { name: 'Leave Form', url: '/leaveforms', icon: FiHome,  },
  { name: 'Request Form', url: '/samplerequests', icon: FiHome,  },
  { name: '[Draft] 견적서', url: '/quotations_draft', icon: FiTrendingUp },
  { name: '게시판', url: '/boards', icon: FiTrendingUp },
  // { name: '수리폼', url: '/repairs', icon: FiSettings },
  // { name: 'T', url: '/home', icon: FiStar },
];
export default function SidebarWithHeader({
  children
}: {
  children?: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', lg: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, children, ...rest }: SidebarProps) => {

const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);

function RoleCheck() {
  if(userData?.role == "Boss") {
    return <>
    {SalesLinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} url={link.url}>
        {link.name}
      </NavItem>
    ))}
    </>
  }
  else if(userData?.role == "Manager") {
    return <>
    {ManagerLinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} url={link.url}>
        {link.name}
      </NavItem>
    ))}
    </>
  }
  else if(userData?.role == "Sales Team") {
    return <>
    {SalesLinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} url={link.url}>
        {link.name}
      </NavItem>
    ))}
    </>
  }
  else if(userData?.role == "TS Team") {
    return <>
    {TSLinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} url={link.url}>
        {link.name}
      </NavItem>
    ))}
    </>
  }
  else if(userData?.role == "Management Team") {
    return <>
    {ManagementLinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} url={link.url}>
        {link.name}
      </NavItem>
    ))}
    </>
  }
  else if(userData?.role == "Digital Team") {
    return <>
    {DigitalLinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} url={link.url}>
        {link.name}
      </NavItem>
    ))}
    </>
  }
  else if(userData?.role == "Marketing Team") {
    return <>
    {MarketingLinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} url={link.url}>
        {link.name}
      </NavItem>
    ))}
    </>
  }
  return <></>
}


  return (
    <Box
      transition="0.03s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', lg: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="6" justifyContent="center">
        <Link href={"/"} display={{base: 'flex', lg: 'flex'}}>
        <Text fontSize="md" fontFamily="arial" fontWeight="bold">
          MINIMAX IMPLANT
        </Text>
        </Link>
        <CloseButton display={{ base: 'flex', lg: 'none' }} onClick={onClose} />
      </Flex>
      <RoleCheck/>
      
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  url: string;
  children: React.ReactNode;
}
const NavItem = ({ icon, url, children, ...rest }: NavItemProps) => {
  return (
    <Link href={url} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'gray.400',
          color: 'white',
        }}
        
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { userLoading, isLoggedIn, user } = useUser();
  const { data, isLoading } = useQuery<IUser>([`users`], getMe);

  const bg = useColorModeValue('white', 'gray.900'); 
  const borderColor= useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();
  const mutation = useMutation(logOut, {
    onMutate: () => {
      toastId.current = toast({
        title: "Sign Out...",
        description: "Sad to see you go...",
        status: "loading",
        position: "bottom-right",
      });
    },
    onSuccess: () => {
      if (toastId.current) {
        queryClient.refetchQueries(["me"]);
        toast.update(toastId.current, {
          status: "success",
          title: "Done!",
          description: "See you later!",
        });
      }
    },
  });

  const onLogOut = async () => {
    mutation.mutate();
  };
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, lg: 10 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', lg: 'space-between' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', lg: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      
      <Flex display={{base: 'none', lg: "flex"}}>
        <Box>
        <Breadcrumb fontSize={{base: '10px', lg: '14px'}} spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
            <BreadcrumbItem>
                <BreadcrumbLink href='#'>Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
                <BreadcrumbLink href='#'>About</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink href='#'>Current</BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
        </Box>
      </Flex>
      <Text
        display={{ base: 'flex', lg: 'none' }}
        fontSize="l"
        fontFamily="arial"
        fontWeight="bold">
        MINIMAX IMPLANT
      </Text>

      <HStack spacing={{ base: '0', lg: '6' }}>

      {!userLoading ? (
          !isLoggedIn ? (
        <Link href={'/login'}><Button>Sign in</Button></Link>
          ) : (
            <>
            <IconButton
              size="lg"
              variant="ghost"
              aria-label="open menu"
              icon={<FiBell />}
            />
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none' }}>
                  <HStack>
                    <Avatar
                      size={'sm'}
                      src={
                        'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                      }
                    />
                    <VStack
                      display={{ base: 'none', lg: 'flex' }}
                      alignItems="flex-start"
                      spacing="1px"
                      ml="2">
                      <Text fontSize="sm">{user?.name}</Text>
                      <Text fontSize="xs" color="gray.600">
                        {data?.role}
                      </Text>
                    </VStack>
                    <Box display={{ base: 'none', lg: 'flex' }}>
                      <FiChevronDown />
                    </Box>
                  </HStack>
                </MenuButton>
                <MenuList
                  bg={bg}
                  borderColor={borderColor}>
                  <MenuItem>Profile</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={onLogOut}>Sign out</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            </>
          )
        ) : null}
        {/* 

        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <VStack
                  display={{ base: 'none', lg: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">Daniel Song</Text>
                  <Text fontSize="xs" color="gray.600">
                    Developer
                  </Text>
                </VStack>
                <Box display={{ base: 'none', lg: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem>Profile</MenuItem>
              <MenuDivider />
              <MenuItem>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        )
        ) : null}
         */}
      </HStack>
    </Flex>
  );
};