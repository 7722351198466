import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingTop: 20,
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: "20px",
    fontSize: 12,
  },
  container_inside: {
    display: 'flex',
    fontSize: 10,
    paddingBottom: 9,
  },
  b: {
    flexDirection: 'row',
    fontSize: 10,
    color: '#58595B',
  },
  b_2: {
    flexDirection: 'row',
    marginTop: '25px',
  },
  b_inside: {
    flexDirection: 'row',
  },
  
})

interface HeaderProps {
    kind: string;
    submittername: string;
    submit_date: string
    minimax_id: string;
    role: string;
    total_days: string;
    children?: React.ReactNode;
  }
function Middle1(props: HeaderProps) {
return (
    <View style={styles.container}>
        <View style={styles.b}>
            <View style={{flex: "1",}}>
                <View style={styles.container_inside}>

                    <View style={styles.b}>
                        <View style={{flex: "2",}}>
                            
                        </View>
                        <View style={{marginLeft: '1px',flex: "9",}}>
                            
                        </View>
                    </View>

                </View>
            </View>
            
            <View style={{}}>
                <View style={styles.container_inside}>
                    <View style={styles.b_2}>
                        <View style={{}}>
                            <Text>Date: </Text>
                            <Text>Minimax ID: </Text>
                            <Text>Name: </Text>
                            <Text>Team: </Text>
                            <Text>Total Days: </Text>
                        </View>
                        <View style={{marginLeft: '1px'}}>
                            <Text style={{marginLeft: '10px'}}>{props.submit_date}</Text>
                            <Text style={{marginLeft: '10px'}}>{props.minimax_id}</Text>
                            <Text style={{marginLeft: '10px'}}>{props.submittername}</Text>
                            <Text style={{marginLeft: '10px'}}>{props.role}</Text>
                            <Text style={{marginLeft: '10px'}}>{props.total_days}</Text>
                        </View>
                        
                    </View>
                    
                </View>

            </View>
            
        </View>
    </View>
    
)
}

export default Middle1
