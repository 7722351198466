import { Box, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import manual_login from "../components/img/manual_login.png"
import SearchProductMain from "../components/ProductData/SearchProductMain";
import Board from "./Board/Board";
import People from "./People";

export default function Home() {
  return (
    <>
    <Tabs variant='soft-rounded' colorScheme='green'>
      <TabList>
        <Tab>제품정보</Tab>
        <Tab>카탈로그 (예정)</Tab>
        <Tab>여기에 적으셔도 돼요</Tab>
        <Tab>2024 Minimax Target</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
        <SearchProductMain></SearchProductMain>
        </TabPanel>
        <TabPanel>
          <p>two!</p>
        </TabPanel>
        <TabPanel>
          <Board></Board>
        </TabPanel>
        <TabPanel>
          <People></People>
        </TabPanel>
      </TabPanels>
    </Tabs>
    
    
    </>
  );
}


