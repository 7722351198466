import { Badge, Box, Button, HStack, Stack, Td, Tr  } from "@chakra-ui/react";
import { VscFilePdf } from "react-icons/vsc";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ILeaveformDates } from "../../types";
import { getMe } from "../../api";
import { useQuery } from '@tanstack/react-query';

interface ILeaveformProp {
    id: number;
    leaveform_id: string;
    submission_date: string;
    reasonForRequest: string;
    remark: string;
    submitter: string;
    kind: string;
    leaveTotalDays: string;
    leaveform_dates: ILeaveformDates[];


}

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}

export default function Leaveforms({
    id,
    leaveform_id,
    submission_date,
    reasonForRequest,
    remark,
    submitter,
    kind,
    leaveform_dates,
    leaveTotalDays
}: ILeaveformProp) {

    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);

    const navigate = useNavigate();


    function leaveType() {
        if(kind === "ANNUAL LEAVE") {
            return <Td fontSize="xs" padding="0" ><Badge colorScheme="blue">{kind}</Badge></Td>
        } 
        else if(kind ==="SICK LEAVE") {
            return <Td fontSize="xs" padding="0"><Badge colorScheme="red">{kind}</Badge></Td>
        }
        else if(kind ==="CARER'S LEAVE") {
            return <Td fontSize="xs" padding="0"><Badge colorScheme="green">{kind}</Badge></Td>
        }
        return <Td fontSize="xs">-</Td>
    }
    return (
        <>            
            <Tr>
                <Td ><Link to={`/leaveforms/${id}`}><Button size="xs" colorScheme="gray">{leaveform_id}</Button></Link></Td>
                {userData?.role == "Boss" && (
                    <Td><Link to={`/leaveforms/${id}/edit`}><Button size="xs" colorScheme="gray">추가설명</Button></Link></Td>
                )}
                {userData?.role == "Manager" && (
                    <Td><Link to={`/leaveforms/${id}/edit`}><Button size="xs" colorScheme="gray">추가설명</Button></Link></Td>
                )}
                <Td fontSize="xs" >{submission_date}</Td>
                <Td fontSize="xs">
                    {leaveform_dates.map((date, index) => (
                        <Box key={index}>
                            {date.leaveStartDay}/{date.leaveStartMonth}/{date.leaveStartYear} ~ {date.leaveEndDay}/{date.leaveEndMonth}/{date.leaveEndYear}
                        </Box>
                    ))}
                </Td>
                <Td fontSize="xs" >{leaveTotalDays}</Td>
                <Td fontSize="xs" >{submitter}</Td>
                <Td fontSize="xs" >{ leaveType() }</Td>
                
            </Tr>

            
        </>

    )
}