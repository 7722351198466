import { Badge, Button, HStack, Stack, Table, TableContainer, Tbody, Td, Text, Thead, Tr  } from "@chakra-ui/react";
interface IProductDataProp {
    id: number;
    code: string;
    pname: string;
    price: string;

}
export default function ProductDatas({
    id,
    code,
    pname,
    price,
}: IProductDataProp) {
    
    return (
        <>            
                            <Tbody>
                                <Tr>
                                    <Td fontSize="10px">{code}</Td>
                                    <Td fontSize="10px">{pname}</Td>
                                    <Td fontSize="10px">{price}</Td>
                                </Tr>
                            </Tbody>
                            
                        
            
            
        </>

    )
}