import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, HStack, Skeleton, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, Link, Stack, Flex, FormControl, InputGroup, InputLeftElement, IconButton, Input, Spacer, useColorModeValue } from "@chakra-ui/react";
import { VscFilePdf } from 'react-icons/vsc';
import BoardSkeleton from "../../components/Board/BoardSkeleton";
import Boards from '../../components/Board/Boards';
import { useQuery } from '@tanstack/react-query';
import { IBoardList } from "../../types";
import { getMe, getBoards } from '../../api';
import useUser from '../../lib/useUser';
import { SearchIcon } from '@chakra-ui/icons';

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}
export default function Board(props: {
	variant?: string;
	background?: string;
	children?: JSX.Element;
	placeholder?: string;
	borderRadius?: string | number;
	[x: string]: any;
}) {

    const { variant, background, children, placeholder, borderRadius, ...rest } = props;
	// Chakra Color Mode
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const inputText = useColorModeValue('gray.700', 'gray.100');

    const { isLoading, data } = useQuery<IBoardList[]>(["boards"], getBoards);
    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const [query, setQuery] = useState("");
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let inputHandler = (e:any) => {
                var lowerCase = e.target.value.toLowerCase();
                setQuery(lowerCase)
            }
        },3000)
        return () => clearTimeout(delayDebounceFn)
    }, [])    
    
    let boardList;
    function RoleCheck() {
        if(userData?.role === "Boss" ) {
            return <Tbody>
            {data?.filter((data) => { 
                if(query === '') { 
                    return data;
                } else if (data.submitter.name.toLowerCase().includes(query)) {
                    return data;
                }
                }).map((board) => (
            boardList = <Boards
            key={board.id}
            id={board.id}
            submission_date={board.submission_date}
            board_id={board.board_id}
            title={board.title}
            description={board.description}
            submitter={board.submitter.name}
            
            /> 
            ))}
            </Tbody>
        }   else if(userData?.role === "TS Team") {
            return <Tbody>
            {data?.filter((data) => { 
                if(query === '') { 
                    return data;
                } else if (data.submitter.name.toLowerCase().includes(query)) {
                    return data;
                }
                }).map((board) => (
            boardList = <Boards
            key={board.id}
            id={board.id}
            submission_date={board.submission_date}
            board_id={board.board_id}
            title={board.title}
            description={board.description}
            submitter={board.submitter.name}
            
            /> 
            ))}
            </Tbody>
        }  else if(userData?.role === "Sales Team") {
            return <Tbody>
            {data?.filter((data) => { 
                if(query === '') { 
                    return data;
                } else if (data.submitter.name.toLowerCase().includes(query)) {
                    return data;
                }
                }).map((board) => (
            boardList = <Boards
            key={board.id}
            id={board.id}
            submission_date={board.submission_date}
            board_id={board.board_id}
            title={board.title}
            description={board.description}
            submitter={board.submitter.name}
            
            /> 
            ))}
            </Tbody>
        }  else if(userData?.role === "Admin Team") {
            return <Tbody>
            {data?.filter((data) => { 
                if(query === '') { 
                    return data;
                } else if (data.submitter.name.toLowerCase().includes(query)) {
                    return data;
                }
                }).map((board) => (
            boardList = <Boards
            key={board.id}
            id={board.id}
            submission_date={board.submission_date}
            board_id={board.board_id}
            title={board.title}
            description={board.description}
            submitter={board.submitter.name}
            
            /> 
            ))}
            </Tbody>
        }
        return boardList = <Tbody><Tr><Td></Td></Tr></Tbody>
    }
    return (
        <Grid mt={10} columnGap={7} rowGap={5} templateColumns={{ base: "repeat(1fr)", lg: "repeat(1fr)"}} px={{base: 0, lg: 10}}>
            {isLoading ? (
            <>
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                <BoardSkeleton />
                
                
            </>
            ) : null }
            <Flex>
            <FormControl>
            <InputGroup w={{ base: '100%', md: '50%' }} {...rest}>
                <InputLeftElement
                    children={
                        <IconButton
                            aria-label='search'
                            bg='none'
                            borderRadius='inherit'
                            _hover={{
                                bg: 'none',
                            }}
                            _active={{
                                bg: 'none',
                                transform: 'none',
                                borderRadius: 'inherit',
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderRadius:'inherit'
                            }}
                            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' borderRadius='inherit'
                            />}
                        />
                    }
                />
                <Input
                    variant='search'
                    border="1px solid #f0f0f0"

                    fontSize='sm'
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius={borderRadius ? borderRadius : '30px'}
                    placeholder={placeholder ? placeholder : 'Search...'}
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    />
            </InputGroup>
            </FormControl>
            
            <Spacer />

            <Link href="/boards/upload">
                <Button size="sm">+ New Board</Button>
            </Link>
            </Flex>
            <Box>
                <HStack>
                    <TableContainer w="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th w="100px" textAlign="center">Board ID</Th>
                                    <Th w="100px" textAlign="center">Date</Th>
                                    <Th w="150px" textAlign="center">Author</Th>
                                    <Th >Title</Th>
                                </Tr>
                            </Thead>
                            <RoleCheck />
                        </Table>
                    </TableContainer>
                </HStack>
            </Box>
        </Grid>
    )
}  