import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, IconButton, Input, InputGroup, InputLeftElement, Stack, Table, TableContainer, Td, Thead, Tr, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { getProductDatas } from "../../api";
import { useState } from "react";
import {  useQuery } from "@tanstack/react-query";

import React from "react";
import ProductDatas from "./ProductDatas";
import { IProductDataList } from "../../types";
import { SearchIcon } from "@chakra-ui/icons";

export default function SearchProduct() {
    const searchIconColor = useColorModeValue('gray.700', 'white');
    const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const inputText = useColorModeValue('gray.700', 'gray.100');
    const { isLoading, data, isPreviousData } = useQuery<IProductDataList[]>(["productdata"], getProductDatas);
    const [query, setQuery] = useState("")
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef(null)

    const filteredProducts = data?.filter(productdata => {
        if (query === '') {
            return true;
        } else {
            const queryWords = query.toLowerCase().split(' ').filter(Boolean);
            return queryWords.every(word =>
                productdata.code.toLowerCase().includes(word) ||
                productdata.pname.toLowerCase().includes(word) ||
                productdata.price.toLowerCase().includes(word)
            );
        }
    });
    console.log("Filtered Products:", filteredProducts); // 필터링된 데이터 콘솔 출력
    return (
        <>
        <Flex justify="flex-end" position="fixed" top="50%" right="40px" transform="translate(50%, -50%)">
            <Button colorScheme="teal" onClick={onOpen}>
                제품
            </Button>
        </Flex>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
          size={{base: 'xl', lg: 'xl', md: 'md'}}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>원하는 제품을 입력하세요 ($130 이상 제품군)</DrawerHeader>
  
            <DrawerBody>
            <FormControl>
                <InputGroup w={{ base: '100%' }}  >
                <InputLeftElement
                    children={
                        <IconButton
                            aria-label='search'
                            bg='none'
                            borderRadius='inherit'
                            _hover={{
                                bg: 'none',
                            }}  
                            _active={{
                                bg: 'none',
                                transform: 'none',
                                borderRadius: 'inherit',
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderRadius:'inherit'
                            }}
                            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' borderRadius='inherit'
                            />}
                        />
                    }
                />
                <Input
                    variant='search'
                    border="1px solid #f0f0f0"

                    fontSize='sm'
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    value={query}
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    />

                </InputGroup>
            </FormControl>
            <Stack>
            <TableContainer w="100%">
                        <Table size='sm' marginTop={"30px"}>
                            <Thead>
                                <Tr>
                                    <Td>Code</Td>
                                    <Td>Description</Td>
                                    <Td>Price</Td>
                                </Tr>
                            </Thead>
                {filteredProducts?.map(productdata => (
                    <ProductDatas 
                        key={productdata.id}
                        id={productdata.id}
                        code={productdata.code}
                        pname={productdata.pname}
                        price={productdata.price}
                    />
                ))}
                </Table>
                    </TableContainer>
            </Stack>
            </DrawerBody>
            
          </DrawerContent>
        </Drawer>
      </>
    )
}