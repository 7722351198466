import { StyleSheet, Text, View, Font, Image } from '@react-pdf/renderer';
import latoBold from './fonts/Lato-Bold.ttf';
import latoRegular from './fonts/Lato-Regular.ttf';

import logo from '../../img/minimax.png'

import Opensans1 from "../../font/open-sans-700.ttf"
import Opensans2 from "../../font/open-sans-regular.ttf"

Font.register({
    family: 'Open Sans',
    fonts: [
      {
        src: Opensans1,
      },
      {
        src: Opensans2,
        FontWeight: "400"
      },
      
    ],
  });

const styles = StyleSheet.create({
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft:"60px", paddingRight:"40px", paddingTop:"40px", paddingBottom: "30px",
  },

  container: {
    backgroundColor: '#101031',
    fontFamily: "Open Sans",
    color: "#fff",
    paddingBottom: 16,
  },
  
  title: {
    color: '#fff', fontSize: '24px',letterSpacing: "1px", marginBottom: "4px", fontWeight: 700, fontFamily: "Open Sans",
  },
  
  sub1: {
    display: 'flex',
    paddingLeft: "60px",
    paddingRight: "60px",
  },
  sub1D: {
    flexDirection: 'row-reverse',
  },
  sub2: {
    display: 'flex',
    paddingLeft: "60px",
    paddingRight: "60px",
    },
  sub2D: {
    flexDirection: 'row',
    justifyContent: "space-between",
  },
  
  total: {

  }
})

interface HeaderProps {
    salesagreement_id: string;
    date: string;
    clinic: string;
    final: string;
    children?: React.ReactNode;
  }
function Header(props: HeaderProps) {
  return (
    <View style={styles.container}>
      <View>
        <View>
          <View style={styles.headerTop} >
            <View>
              <Text style={styles.title}>SALES AGREEMENT</Text>
              <Text style={{ color: '#fff', fontSize: '11px', letterSpacing: "0.9px", marginBottom: "4px", fontWeight: "bold"}}>MINIMAX IMPLANT PTY LTD</Text>
              <Text style={{ color: '#fff', fontSize: '6px', marginBottom: "2px"}}>ABN. 60 154 715 705</Text>
              <Text style={{ color: '#fff', fontSize: '6px', marginBottom: "2px"}}>PH. 02 8084 2900    |    Email. info@minimaximplant.com.au</Text> 
              <Text style={{ color: '#fff', fontSize: '6px', marginBottom: "2px"}}>Address. Suite 4.05, Level 4, 6 Eden Park Drive, Macquarie Park, NSW 2113</Text>
            </View>
            
            <View>            
              <Image style={{width:"50px", marginLeft: "-10px"}} src={logo}/>
            </View>
          </View>
          
        </View>

        {/* 
        <View style={styles.sub2}>
          <View style={styles.sub2D}>
            <View style={{flexDirection: "row", flex: 3, paddingBottom: "15px", justifyContent:"center"}}>
              <Text style={{ color: '#fff', fontSize: '12px', letterSpacing: "0.9px", marginBottom: "0px", marginTop: "6px", fontFamily:"Open Sans", fontWeight: 700}}>" {props.clinic} "</Text>
            </View>
            
          </View>
        </View>
         */}
        
        <View style={styles.sub2}>
          <View style={styles.sub2D}>
            <View style={{flexDirection: "row", flex: 3 }}>
            </View>
            <View style={{flexDirection: "row" }}>
              <View style={{ marginRight: "10px", marginTop:"5px", alignItems: "flex-end"}}>
                <Text style={{ color: '#fff', fontSize: '10px', marginBottom: "4px"}}>TOTAL CONTRACT AMOUNT</Text>
                <Text style={{ color: '#fff', fontSize: '6px', marginBottom: "4px"}}>(INC.GST)</Text>
              </View>
              <View style={{backgroundColor:"#fff", paddingLeft: "15px", paddingRight: "15px", paddingTop: "5px", borderRadius: "20px", justifyContent:"center", alignItems: "center", width: "170px"}}>
                <View style={{flexDirection: "row", }}>
                  <Text style={{ color: '#292929',  fontSize: '10px', marginBottom: "4px", fontWeight: "ultrabold", marginRight: "10px"}}>AUD</Text>
                  {(props.final == "0.00") ?
                  <>
                  <Text style={{ color: '#292929',  fontSize: '9px',letterSpacing: "1px", marginBottom: "4px", fontWeight: "ultrabold", borderBottom: "1px solid #f0f0f0"}}>$ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                  </>
                  :
                  <>
                  <Text style={{ color: '#292929',  fontSize: '9px',letterSpacing: "1px", marginBottom: "4px", fontWeight: "ultrabold", }}>$ {props.final}</Text>
                  </>
                  }
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
    
  )
}

export default Header
