import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    padding: 20,
    backgroundColor: '#000',
    fontSize: 12,
    paddingBottom: 16,
  },
  b: {
    flexDirection: 'row-reverse',
  },
  left: {
    flex: 1,
  },
  rightFlex: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
    color: '#fff',
    fontSize: 9,
  },
})

interface HeaderProps {
    sr_id: string;
    children?: React.ReactNode;
  }
function Header3(props: HeaderProps) {
  return (
    <View style={styles.container}>
      
      <View style={styles.b}>
        <View>
          <Text style={{ color: '#fff', fontSize: '11px', marginLeft: '10px', marginBottom: '5px'}}>MINIMAX IMPLANT PTY LTD</Text>
          <Text style={{ color: '#fff', fontSize: '7px', marginLeft: '10px'}}>ABN. 60 154 715 705</Text>
          <Text style={{ color: '#fff', fontSize: '7px', marginLeft: '10px'}}>PH. 02 8084 2900</Text>
          <Text style={{ color: '#fff', fontSize: '7px', marginLeft: '10px'}}>Email. info@minimaximplant.com.au</Text>
          <Text style={{ color: '#fff', fontSize: '7px', marginLeft: '10px'}}>Address. Suite 4.05, Level 4, 6 Eden Park Drive,</Text>
          <Text style={{ color: '#fff', fontSize: '7px', marginLeft: '10px'}}>Macquarie Park NSW 2113</Text>
        </View>
        <View>
          <Text style={{border: "1.5px solid #fff", height: "70px", marginLeft: "20px", backgroundColor: "#fff"}}></Text>
        </View>
        <View style={{alignItems: "flex-end", justifyContent: "flex-end"}}>
          <Text style={{ fontSize: '8px', color: '#fff', textAlign: 'right' }}>MINIMAX IMPLANT & MEDICAL</Text>
          <Text style={{ color: '#fff', fontSize: '24px'}}>DRAFT PROPOSAL</Text>
        </View>
      </View>
    </View>
    
  )
}

export default Header3
