import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    borderTop: '1px solid #999',
    backgroundColor: '#E2E2E2',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 17,
    paddingBottom: 17,
  },
  left: {
    flex: 1,
  },
  right: {
    fontStyle: 'italic',
    
  },
  hrAddress: {
    width: 125,
    border: '0.5px solid #292929',
    marginTop: 2,
    marginBottom: 2,
  },
  hrPhone: {
    width: 50,
    border: '0.5px solid #292929',
    marginTop: 2,
    marginBottom: 2,
  },
  hrEmail: {
    width: 105,
    border: '0.5px solid #292929',
    marginTop: 2,
    marginBottom: 2,
  },
  footerAddress: {
    fontSize: 9,
    fontWeight: 'bold',
    marginRight: 20,
  },
  footerPhone: {
    fontSize: 9,
    fontWeight: 'bold',
    marginRight: 20,
  },
  footerEmail: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  footerTitle: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  footerContent: {
    fontSize: 8,
  }
})

function Footer() {
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Text>Minimax Implant & Dental</Text>
      </View>
      <View style={styles.footerAddress}>
        <Text style={styles.footerTitle}>Address</Text>
        <Text style={styles.hrAddress}></Text>
        <Text style={styles.footerContent}>Suite 4.05, Level 4, 6 Eden Park Drive,</Text>
        <Text style={styles.footerContent}>Macquarie Park NSW 2113</Text>
      </View>
      <View style={styles.footerPhone}>
        <Text style={styles.footerTitle}>Phone</Text>
        <Text style={styles.hrPhone}></Text>
        <Text style={styles.footerContent}>02 8084 2900</Text>
      </View>
      <View style={styles.footerEmail}>
        <Text style={styles.footerTitle}>Email</Text>
        <Text style={styles.hrEmail}></Text>
        <Text style={styles.footerContent}>info@minimaximplant.com.au</Text>
      </View>
    </View>
  )
}

export default Footer
