import { Box, Grid, HStack, Skeleton } from "@chakra-ui/react";

export default function SalesAgreementSkeleton() {
    return (
        <Box>
            <HStack>
                <Skeleton rounded="2xl" w="10%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="10%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="10%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="10%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="30%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="7%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="7%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="5%" h="50px" bg="gray.200"/>
                <Skeleton rounded="2xl" w="11%" h="50px" bg="gray.200"/>
            </HStack>
        </Box>
    )
}  