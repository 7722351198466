import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingTop: 30,
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: "0px",
    fontSize: 12,
  },
  container_inside: {
    display: 'flex',
    fontSize: 10,
    paddingBottom: 9,
  },
  b: {
    flexDirection: 'row',
    fontSize: 10,
    color: '#58595B',
  },
  b_2: {
    flexDirection: 'row',
    marginTop: '25px',
  },
  b_inside: {
    flexDirection: 'row',
  },
  
})

interface HeaderProps {
    client_name: string;
    client_clinic: string;
    client_address: string;
    client_mobile: string;
    client_email: string;
    client_abn: string;

    quotation_id: string;
    user_name: string;
    date: string;
    expire_date: string;

    children?: React.ReactNode;
  }
function Header(props: HeaderProps) {
return (
    <View style={styles.container}>
        <View style={styles.b}>
            <View style={{flex: "1",}}>
                <View style={styles.container_inside}>
                    <Text style={{ color: '#000', fontSize: '13px', fontWeight: 700, marginBottom: '5px'}}>{props.client_name} & {props.client_clinic}</Text>

                    <View style={styles.b}>
                        <View style={{flex: "1.5",}}>
                            <Text>Phone: </Text>
                            <Text>Email: </Text>
                            <Text>ABN: </Text>
                            <Text>Address: </Text>
                        </View>
                        <View style={{marginLeft: '1px',flex: "9",}}>
                            <Text>{props.client_mobile}</Text>
                            <Text>{props.client_email}</Text>
                            <Text>{props.client_abn}</Text>
                            <Text>{props.client_address}</Text>
                        </View>
                        
                    </View>
                </View>
            </View>
            
            <View style={{}}>
                <View style={styles.container_inside}>
                    <View style={styles.b_2}>
                        <View style={{}}>
                            <Text>Quotation ID: </Text>
                            <Text>Sales Rep: </Text>
                            <Text>Date: </Text>
                            <Text>Expire Date: </Text>
                        </View>
                        <View style={{marginLeft: '1px'}}>
                            <Text style={{marginLeft: '10px'}}>{props.quotation_id}</Text>
                            <Text style={{marginLeft: '10px'}}>{props.user_name}</Text>
                            <Text style={{marginLeft: '10px'}}>{props.date}</Text>
                            <Text style={{marginLeft: '10px'}}>{props.expire_date}</Text>
                        </View>
                        
                    </View>
                </View>
                {/* <View style={{ alignItems: 'flex-start' }}>
                <Text style={{ color: '#000', fontSize: '11px', marginLeft: '10px', marginBottom: '5px'}}>MINIMAX IMPLANT PTY LTD</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Quotation No: {props.quotation_id}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Sales Rep: {props.user_name}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Date: {props.date}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Expire Date: {props.expire_date}</Text>
                </View>
                <View style={{ alignItems: 'flex-end' }}>
                <Text style={{ color: '#000', fontSize: '11px', marginLeft: '10px', marginBottom: '5px'}}>MINIMAX IMPLANT PTY LTD</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Quotation No: {props.quotation_id}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Sales Rep: {props.user_name}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Date: {props.date}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Expire Date: {props.expire_date}</Text>
                </View> */}
            </View>
            
        </View>
    </View>
    
)
}

export default Header
