import { Badge, Box, Button, Checkbox, Container, FormControl, FormHelperText, FormLabel, Grid, Heading, HStack, Input, Select, Stack, Textarea, useToast, VStack, Text, Icon, IconButton } from "@chakra-ui/react";
import { getQuoteTNC, IUploadBoardVariables, uploadBoard } from "../../api";
import ProtectedPage from "../../components/ProtectedPage";
import useSalesOnlyPage from "../../components/SalesOnlyPage";
import { IClient, IBoardDetail, IUser } from "../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, Control, useWatch, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { ChangeEvent, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";



export default function UploadBoard() {
    const { control, register, handleSubmit } = useForm<IUploadBoardVariables>();

    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation(uploadBoard, {
    onSuccess: (data: IBoardDetail) => {
        console.log("------------");
        console.log(data);
        console.log("------------");

        toast({
        status: "success",
        title: "Board Form created",
        position: "bottom-right",
        });
        navigate(`/boards/${data.id}`);
    },
    });
    useSalesOnlyPage();
    const onSubmit = (data: IUploadBoardVariables) => {
        console.log(data)
        mutation.mutate(data)
    }
    return (
        <ProtectedPage>
            <Box
                pb={40}
                mt={10}
                px={{  
                    base: 10,
                    lg: 40,
                }}
            >
                <Box>
                    <Heading fontSize="2xl" textAlign={"center"}>Create Board</Heading>
                    <VStack spacing={15} as="form" onSubmit={handleSubmit(onSubmit)} mt={5}>
                    <FormControl>
                            <FormLabel>Title</FormLabel>
                            <Input {...register("title", { required: true })} type="text" />
                            <FormHelperText>Title</FormHelperText>
                    </FormControl>
                    <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea {...register("description", { required: true })}  />
                            <FormHelperText>Description</FormHelperText>
                    </FormControl>    
                        
                    {mutation.isError ? (
                        <Text color="red.500">Something went wrong</Text>
                    ) : null}
                    <Button
                    type="submit"
                    isLoading={mutation.isLoading}
                    colorScheme={"red"}
                    size="lg"
                    w="100%"
                    >
                    Upload Request Form
                    </Button>
                    </VStack>
                </Box>
            </Box>
        </ProtectedPage>
    )
}