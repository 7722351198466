import { createBrowserRouter } from "react-router-dom";
import Root from './components/Root';
import CallToActionWithVideo from "./routes/Test";
import Login from "./routes/Login";
import NotFound from "./routes/NotFound";
import Quotation from "./routes/Quotation/Quotation";
import SalesAgreement from "./routes/SalesAgreement/SalesAgreement";
import QuotationDetail from "./routes/Quotation/QuotationDetail";
import SalesAgreementDetail from "./routes/SalesAgreement/SalesAgreementDetail";
import UploadQuotation from "./routes/Quotation/UploadQuotation";
import UploadSalesAgreement from "./routes/SalesAgreement/UploadSalesAgreement";
import UploadSalesAgreementWithQuotation from "./routes/SalesAgreement/UploadSalesAgreementWithQuotation";
import Repair from "./routes/Repair/Repair";
import UploadRepair from "./routes/Repair/UploadRepair";
import RepairDetail from "./routes/Repair/RepairDetail";
import Board from "./routes/Board/Board";
import UploadBoard from "./routes/Board/UploadBoard";
import BoardDetail from "./routes/Board/BoardDetail";
import Home from "./routes/Home";
import People from "./routes/People";
import UploadQuotation_Draft from "./routes/Quotation_Draft/UploadQuotation_Draft";
import Quotation_Draft from "./routes/Quotation_Draft/Quotation_Draft";
import QuotationDetail_Draft from "./routes/Quotation_Draft/QuotationDetail_Draft";
import UploadQuotationWithDraft from "./routes/Quotation/UploadQuotationWithDraft";
import UploadQuotationWithBefore from "./routes/Quotation/UploadQuotationWithBefore";
import UpdateQuotation from "./routes/Quotation/UpdateQuotation";
import Howtouse from "./routes/Howtouse";
import Samplerequest from "./routes/Samplerequest/Samplerequest";
import SamplerequestDetail from "./routes/Samplerequest/SamplerequestDetail";
import UploadSamplerequest from "./routes/Samplerequest/UploadSamplerequest";
import Leaveform from "./routes/Leaveform/Leaveform";
import LeaveformDetail from "./routes/Leaveform/LeaveformDetail";
import UploadLeaveform from "./routes/Leaveform/UploadLeaveform";
import UpdateSamplerequest from "./routes/Samplerequest/UpdateSamplerequest";
import UpdateSalesAgreement from "./routes/SalesAgreement/UpdateSalesAgreement";
import UpdateLeaveform from "./routes/Leaveform/UpdateLeaveform";


const router = createBrowserRouter([
    {
        path: "/login",
        element: <Login />,
        errorElement: <NotFound />,
    },

    {
        path: "/",
        element: <Root />,
        errorElement: <NotFound />,

        children: [
            {
                path: "/product",
                element: <Home />,
            },
            {
                path: "/people",
                element: <People />,
            },
            {
                path: "/test",
                element: <CallToActionWithVideo/>,
            },
            {
                path: "/howtouse",
                element: <Howtouse />,
            },
            {
                path: "/quotations",
                element: <Quotation/>,
            },
            {
                path: "/quotations/upload",
                element: <UploadQuotation/>,
            },
            
            {
                path: "/quotations_d/:quotationPk/upload",
                element: <UploadQuotationWithDraft/>,
            },
            {
                path: "/quotations/:quotationPk/upload",
                element: <UploadQuotationWithBefore/>,
            },
            {
                path: "/quotations/:quotationPk/update",
                element: <UpdateQuotation/>,
            },
            {
                path: "/quotations/:quotationPk",
                element: <QuotationDetail/>,
            },
            {
                path: "/quotations_draft",
                element: <Quotation_Draft/>,
            },
            {
                path: "/quotations_draft/upload",
                element: <UploadQuotation_Draft/>,
            },
            {
                path: "/quotations_draft/:quotationPk",
                element: <QuotationDetail_Draft/>,
            },
            {
                path: "/repairs",
                element: <Repair/>,
            },
            {
                path: "/repairs/upload",
                element: <UploadRepair/>,
            },
            {
                path: "/repairs/:repairPk",
                element: <RepairDetail/>,
            },
            {
                path: "/boards",
                element: <Board/>,
            },
            {
                path: "/boards/upload",
                element: <UploadBoard/>,
            },
            {
                path: "/boards/:boardPk",
                element: <BoardDetail/>,
            },
            {
                path: "/salesagreements",
                element: <SalesAgreement/>,
            },
            {
                path: "/salesagreements/upload",
                element: <UploadSalesAgreement/>,
            },
            {
                path: "/salesagreements/:salesagreementPk/edit",
                element: <UpdateSalesAgreement/>,
            },
            {
                path: "/salesagreements/:quotationPk/upload",
                element: <UploadSalesAgreementWithQuotation/>,
            },
            {
                path: "/salesagreements/:salesagreementPk",
                element: <SalesAgreementDetail/>,
            },
            {
                path: "/samplerequests",
                element: <Samplerequest/>,
            },
            {
                path: "/samplerequests/:samplerequestPk",
                element: <SamplerequestDetail/>,
            },
            {
                path: "/samplerequests/:samplerequestPk/edit",
                element: <UpdateSamplerequest/>,
            },
            {
                path: "/samplerequests/upload",
                element: <UploadSamplerequest/>,
            },
            {
                path: "/leaveforms",
                element: <Leaveform/>,
            },
            {
                path: "/leaveforms/:leaveformPk",
                element: <LeaveformDetail/>,
            },
            {
                path: "/leaveforms/:leaveformPk/edit",
                element: <UpdateLeaveform/>,
            },
            {
                path: "/leaveforms/upload",
                element: <UploadLeaveform/>,
            },
        ]
    },
]);


export default router;