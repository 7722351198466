import {
    Button,
    Flex,
    Heading,
    Image,
    Link,
    Stack,
    Text,
    useBreakpointValue,
  } from '@chakra-ui/react';
  
  export default function SplitScreen() {
    return (
      <Stack minH={'100vh'} direction={{ base: 'column', lg: 'row' }}>
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={6} w={'full'} maxW={'lg'}>
            <Heading fontSize={{ base: '2xl', md: '3xl', lg: '5xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '20%', lg: '30%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'blue.400',
                  zIndex: -1,
                }}>
                404 Not Found
              </Text>
              <br />{' '}
              <Text color={'blue.400'} as={'span'}>
                I am lost in love with Minimax Implant.
              </Text>{' '}
            </Heading>
            <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
              The project board is an exclusive resource for our lovely team member. It's
              perfect for our super team members.
            </Text>
            <Stack direction={{ base: 'column', lg: 'row' }} spacing={4}>
              <Button
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                  
                }}>
                <Link href='https://www.minimaximplant.com.au' isExternal>
                    Minimax Implant
                </Link>
              </Button>
              <Button
                rounded={'full'}
                bg={'red.400'}
                color={'white'}
                _hover={{
                  bg: 'red.500',
                  
                }}><Link href='https://edentalsupply.com.au' isExternal>Online Shop</Link>
                </Button>
                <Button
                rounded={'full'}
                bg={'gray.400'}
                color={'white'}
                _hover={{
                  bg: 'gray.500',
                  
                }}><Link href='https://www.implantguide.com.au' isExternal>Implant Guide</Link></Button>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image
            alt={'Login Image'}
            objectFit={'cover'}
            src={
              'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            }
            borderBottomLeftRadius={200}

          />
        </Flex>
      </Stack>
    );
  }