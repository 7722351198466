import {  Box, Button, Checkbox,  FormControl, FormHelperText, FormLabel, Grid, Heading, HStack, Input, Select, Textarea, useToast, VStack, Text,  IconButton, Radio, Flex, Stack, Container, InputGroup, InputLeftAddon, InputRightAddon, Highlight, RadioGroup, useColorModeValue, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, InputLeftElement  } from "@chakra-ui/react";
import { IUploadLeaveformVariables, getLeaveform, uploadLeaveform, updateLeaveform } from "../../api";
import ProtectedPage from "../../components/ProtectedPage";
import useSalesOnlyPage from "../../components/SalesOnlyPage";
import { ILeaveformDetail,  ILeaveformDates,  ISATNC } from "../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, Control, useWatch, useFieldArray, Controller } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus,  } from "react-icons/ai";
import { FaDivide } from "react-icons/fa6";
import { useEffect, useState } from "react";

import "../../components/css/App.css";
import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function UpdateLeaveform() {

    const { leaveformPk } = useParams<{ leaveformPk: string }>();
    const id = parseInt(leaveformPk || "0", 10);


    const { control, register, handleSubmit, formState:{errors}, watch, setValue, getValues, resetField } = useForm<IUploadLeaveformVariables>({
        defaultValues: {
            leaveform_dates: [{
            }]
        }
    });



    const { isLoading, data } = useQuery<ILeaveformDetail>([`leaveform_dates`, leaveformPk], getLeaveform);
    const leaveform_dates = data?.leaveform_dates!

    useEffect(() => {
        if (data) {

            setValue('kind', data.kind);
            setValue('leaveTotalDays', data.leaveTotalDays);
            setValue('remark', data.remark);
            setValue('reasonForRequest', data.reasonForRequest);
            resetField( "leaveform_dates", { defaultValue: leaveform_dates });

        }
    }, [data, setValue]);




    const { fields, append, remove } = useFieldArray({
        name: "leaveform_dates", // unique name for your Field Array
        control,// control props comes from useForm (optional: if you are using FormContext)
        rules: {
            required: "Please append at least 1 item",
        }

    });
    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation((data: IUploadLeaveformVariables) => updateLeaveform(id, data), {

    // const mutation = useMutation(uploadLeaveform, {
        onError: (error) => {
            console.log(error);
            console.log("test")
        },
        onSuccess: (data: ILeaveformDetail) => {

            toast({
            status: "success",
            title: "Leave form Created",
            position: "bottom-right",
            });
            navigate(`/leaveforms/${data.id}`);
        },
    });
    useSalesOnlyPage();
    const onSubmit = (data: IUploadLeaveformVariables) => {

        mutation.mutate(data);
    }
    return (
        <ProtectedPage>
            <Box
            padding={20}
            backgroundColor='#292929'
            color="#fff"
            pb="16">
                <Box textAlign="center" fontSize="20">UPDATE LEAVE FORM</Box>
                
            </Box>
            
            
            <Box display="flex" justifyContent="space-between" mb="20" mt="20px">
                    <Box><Link to={`/leaveforms/`}><Button colorScheme='facebook' variant='outline'size={{base: "xs", lg: "lg"}}>&#x2190; Leave Form 리스트</Button></Link></Box>
                    
            </Box>

            
            
            {/* 
            ***
            1. Client Information
            ***
            */}
            
            <Box
                pb={40}
                mt={10}
                px={{  
                    base: "20px",
                    lg: "10vw",
                }}
            >   
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query={['Leave Form 의 타입']}
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    1.  Leave Form 의 타입 (Annual Leave or Sick / Carer's Leave) 을 입력해주세요
                </Highlight>
                </Heading>
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        <FormControl>
                            <HStack>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Leave Form Type:</FormLabel>
                            <Select disabled {...register("kind", { required: true })}
                                fontSize="15px"
                                placeholder="Choose a kind"
                                w={{base: "150px", lg: "200px"}}
                                variant="flushed"
                                textAlign="center"
                            >
                                <option value="ANNUAL LEAVE">ANNUAL LEAVE</option>
                                <option value="SICK LEAVE">SICK LEAVE</option>
                                <option value="CARER'S LEAVE">CARER'S LEAVE</option>
                            </Select>
                            </HStack>
                        </FormControl>
                    </FormControl>
                </FormControl>
                </Box>

                    {/* <Calendar prev2Label={null} next2Label={null} minDate={new Date()} selectRange></Calendar> */}


                <Box mb={{base: "50px", lg: "100px"}}>
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query={['시작날짜', '종료날짜']}
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    2.  Leave Form 의  시작날짜 & 종료날짜 를 입력해주세요
                </Highlight>
                </Heading>
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        <FormControl>
                                    {fields.map((field,index)=>(
                                        <FormControl key={field.id} pb="10px">
                                            <HStack>
                                            <FormLabel w={{base: "100%", lg: "100%"}} display="inline-block" textAlign={{base: "left", lg: "right"}} ml="30px" mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>시작날짜:</FormLabel>
                                            <FormControl>
                                                <InputGroup size="sm">
                                                <Input disabled {...register(`leaveform_dates.${index}.leaveStartDay` as const,{ required: true, })} type="text" variant='flushed' textAlign="center" placeholder="Day" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                                </InputGroup>
                                            </FormControl>
                                            <Text>/</Text>
                                            <FormControl>
                                            <InputGroup size="sm">
                                                <Select disabled {...register(`leaveform_dates.${index}.leaveStartMonth`as const,{ required: true, })}
                                                    variant='flushed' textAlign="center" placeholder="Month" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}
                                                >
                                                    <option value="Jan">January</option>
                                                    <option value="Feb">February</option>
                                                    <option value="Mar">March</option>
                                                    <option value="Apr">April</option>
                                                    <option value="May">May</option>
                                                    <option value="Jun">June</option>
                                                    <option value="Jul">July</option>
                                                    <option value="Aug">August</option>
                                                    <option value="Sep">September</option>
                                                    <option value="Oct">October</option>
                                                    <option value="Nov">November</option>
                                                    <option value="Dec">December</option>
                                                </Select>
                                                </InputGroup>
                                            </FormControl>
                                            <Text>/</Text>
                                            <FormControl>
                                                <InputGroup size="sm">
                                                <Input disabled {...register(`leaveform_dates.${index}.leaveStartYear`as const,{ required: true, })} type="text" variant='flushed' textAlign="center" placeholder="Year" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                                </InputGroup>
                                            </FormControl>
                                            <FormLabel w={{base: "100%", lg: "100%"}} display="inline-block" textAlign={{base: "left", lg: "right"}} ml="30px" mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>종료날짜:</FormLabel>
                                            <FormControl>
                                                <InputGroup size="sm">
                                                <Input disabled {...register(`leaveform_dates.${index}.leaveEndDay`as const,{ required: true, })} type="text" variant='flushed' textAlign="center" placeholder="Day" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                                </InputGroup>
                                            </FormControl>
                                            <Text>/</Text>
                                            <FormControl>
                                            <InputGroup size="sm">
                                                <Select disabled {...register(`leaveform_dates.${index}.leaveEndMonth`as const,{ required: true, })}
                                                    variant='flushed' textAlign="center" placeholder="Month" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}
                                                >
                                                    <option value="Jan">January</option>
                                                    <option value="Feb">February</option>
                                                    <option value="Mar">March</option>
                                                    <option value="Apr">April</option>
                                                    <option value="May">May</option>
                                                    <option value="Jun">June</option>
                                                    <option value="Jul">July</option>
                                                    <option value="Aug">August</option>
                                                    <option value="Sep">September</option>
                                                    <option value="Oct">October</option>
                                                    <option value="Nov">November</option>
                                                    <option value="Dec">December</option>
                                                </Select>
                                                </InputGroup>
                                            </FormControl>
                                            <Text>/</Text>
                                            <FormControl>
                                                <InputGroup size="sm">
                                                <Input disabled {...register(`leaveform_dates.${index}.leaveEndYear`as const,{ required: true, })} type="text" variant='flushed' textAlign="center" placeholder="Year" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                                </InputGroup>
                                            </FormControl>
                                            <HStack>
                                            <IconButton
                                                size="sm"
                                                aria-label="open menu"
                                                icon={<AiOutlinePlus />}
                                                onClick={() =>
                                                    append({
                                                        leaveStartDay: "",
                                                        leaveStartMonth: "",
                                                        leaveStartYear: "",
                                                        leaveEndDay: "",
                                                        leaveEndMonth: "",
                                                        leaveEndYear: "",
                                                    })
                                                }
                                                variant="outline"
                                            />
                                            <IconButton
                                                size="sm"
                                                aria-label="open menu"
                                                icon={<AiOutlineMinus />}
                                                onClick={() => remove(index)}
                                                variant="outline"

                                            />
                                            </HStack>
                                            </HStack>
                                        </FormControl>
                                        ))}
                                    
                        </FormControl>
                    </FormControl>
                    
                </FormControl>
                </Box>
                <Box mb={{base: "50px", lg: "100px"}}>
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='총 Days'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    3.  Leave Form 의 총 Days 를 입력해주세요
                </Highlight>
                </Heading>
                <FormControl pt="30px" pb="0px">
                    <FormControl>
                        <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}}mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Total Days:</FormLabel>
                        <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' disabled {...register("leaveTotalDays", { required:true })} type="text" pl={{base: "10px", lg:"20px"}} placeholder="Total Days" _placeholder={{ opacity: 1, color:'gray.400', size: "8px"}}/>
                    </FormControl>
                </FormControl>
                
                </Box>                        
                {/* 
                ***
                5. Sales Agreement Special Conditions
                ***
                */}
                <Box mb={{base: "50px", lg: "100px"}}>
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='작성하는 이유'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    4.  Leave Form 을 작성하는 이유를 입력해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Special Condition</FormLabel>
                                <Textarea disabled {...register("reasonForRequest", )}  />
                        </FormControl>
                </Box>
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='추가로 언급해야 되는 사항'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    5.  Leave Form 에 추가로 언급해야 되는 사항 을 입력해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Special Condition</FormLabel>
                                <Textarea disabled {...register("remark", )}  />
                        </FormControl>
                </Box>
                

                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='추가 Comment'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    6.  Leave Form 에 추가 Comment 를 입력해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Additional Comment</FormLabel>
                                <Textarea {...register("comment", )}  />
                        </FormControl>
                </Box>
                
                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">6.</Text><Text display="inline-block" textDecoration="underline">TERMS & CONDITION</Text></Box> */}

                <Box>
                    <VStack spacing={15} as="form" onSubmit={handleSubmit(onSubmit)} mt={5}>
                    
                    {/* <FormControl>
                        <FormLabel>Quotation Category </FormLabel>
                        <Select {...register("kind", { required: true })}
                            placeholder="Choose a kind"
                        >
                            <option value="Implant">Implant</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Seminar">Seminar</option>
                        </Select>
                    </FormControl> */}


x
                    <p>{errors.leaveform_dates?.root?.message}</p>

                    
                    
                    
                    {mutation.isError ? (
                        <Text color="red.500">Something went wrong</Text>
                    ) : null}
                    <Button
                    type="submit"
                    isLoading={mutation.isLoading}
                    colorScheme="facebook"
                    size="lg"
                    w={{base: "100%", lg: "200px"}}
                    >
                    Leave Form 생성하기
                    </Button>
                    </VStack>
                </Box>
            </Box>
        </ProtectedPage>
    )
}
