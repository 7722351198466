import {  Box, Button, Checkbox,  FormControl, FormHelperText, FormLabel, Grid, Heading, HStack, Input, Select, Textarea, useToast, VStack, Text,  IconButton, Flex, Hide, InputGroup, InputLeftAddon, InputRightAddon, Highlight, useColorModeValue, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Stack, InputLeftElement } from "@chakra-ui/react";
import {getProductDatas, getQuotation, getSATNC, IUploadSalesAgreementVariables, uploadSalesAgreement } from "../../api";
import ProtectedPage from "../../components/ProtectedPage";
import useSalesOnlyPage from "../../components/SalesOnlyPage";
import { IProductDataList, IQuotationDetail, ISalesAgreementDetail,  ISalesAgreementItems,  ISATNC } from "../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, Control, useWatch, useFieldArray, Controller, FormProvider } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { FaDivide } from "react-icons/fa6";
import React from "react";
import { SearchIcon } from "@chakra-ui/icons";
import ProductDatas from "../../components/ProductData/ProductDatas";
import SearchProduct from "../../components/ProductData/SearchProduct";


function getTotalPrice(payload: IUploadSalesAgreementVariables['salesagreement_items']) {
    let total:any = 0;

    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))

        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
        
    }
    total = (Math.round(total * 100) / 100 / 11 * 10).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return total;
}

function getGst(payload: IUploadSalesAgreementVariables['salesagreement_items']) {
    let total:any = 0;

    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))

        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100 / 11).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    return total;

}       

function getFinalTotal(payload: IUploadSalesAgreementVariables['salesagreement_items']) {
    let total:any = 0;
    
    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))

        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return total;
}

function getPayment(payload: IUploadSalesAgreementVariables['salesagreement_pays']) {
    let total:any = 0;
    
    for (const item of payload) {
        total += (Number.isNaN(item.price) ? 0 : item.price) * (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return total;
}
function getAmount(payload: IUploadSalesAgreementVariables['salesagreement_items']) {
    let total:any = 0;

    let amount:any= 0;

    payload.forEach((data, index) => {
        amount = data.amount
        data.amount= ((Number.isNaN(data.qty)) || (Number.isNaN(data.unit_price)) ? 0 : data.qty*(data.unit_price + (data.unit_price/10)))
        amount = data.amount.toFixed(2)
        amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // amount = parseInt(`data.${index}.amount`)
        return amount;
    })
    
}
function Amount({control}: {control: Control<IUploadSalesAgreementVariables>}) {
    const values = useWatch({
        control,
        name: `salesagreement_items`,
    });
    return <>{getAmount(values)}</>
}
function TotalPrice({control}: {control: Control<IUploadSalesAgreementVariables>}) {
    const values = useWatch({
        control,
        name: "salesagreement_items",
    });

    return <p>{getTotalPrice(values)}</p>
}
function Gst({control}: {control: Control<IUploadSalesAgreementVariables>}) {
    const values = useWatch({
        control,
        name: "salesagreement_items",
    });

    return <p>{getGst(values)}</p>
}
function FinalPrice({control}: {control: Control<IUploadSalesAgreementVariables>}) {
    const values = useWatch({
        control,
        name: "salesagreement_items",
    });
    return <p>{getFinalTotal(values)}</p>
}

function Payment({control}: {control: Control<IUploadSalesAgreementVariables>}) {
    const values = useWatch({
        control,
        name: "salesagreement_pays",
    });
    return <p>{getPayment(values)}</p>
}

export default function UploadSalesAgreementWithQuotation() {



    
    const styles = {
        
    }

    const [value, setInput] = useState("");
    const [isActive, setIsActive] = useState(false);
    function handleChange(e:any) {
        const text = e.target.value;
        setInput(text);
    }
    const { quotationPk } = useParams();

    const { isLoading, data } = useQuery<IQuotationDetail>([`quotations`, quotationPk], getQuotation);
    const qckind = data?.kind!
    const qspecialcondition = data?.special_condition!
    const qcdentist = data?.client.name!
    const qcclinic = data?.client.clinic!
    const qcaddress = data?.client.address!
    const qcmobile = data?.client.mobile!
    const qcemail = data?.client.email!
    const qcabn = data?.client.abn!
    const qid = data?.quotation_id!
    const special_condition = data?.special_condition!
    
    const qitem = data?.quotation_items!
    const quname = data?.user.name!
    const qdate = data?.date!
    const qexpireddate = data?.expire_date!
    const quote_total_price = data?.quote_total_price!
    const quote_gst_price = data?.quote_gst_price!
    const quote_final_price = data?.quote_final_price!


    const { control, register, handleSubmit, formState:{errors}, watch, setValue, getValues, reset, resetField } = useForm<IUploadSalesAgreementVariables>({
        defaultValues: {
            salesagreement_items: [{
            }],
            salesagreement_pays: [{
            }]
        }
    });

    function ConditionalInput({ control, index}:{control: Control<IUploadSalesAgreementVariables>, index: any}) {
        const value = useWatch({
            name: "salesagreement_items",
            control
        });
        const qty = useWatch({
            name: `salesagreement_items.${index}.qty`,
            control
        });
        const unitPrice = useWatch({
            name: `salesagreement_items.${index}.unit_price`,
            control
        });
        useEffect(() => {
            if (!isNaN(qty) && !isNaN(unitPrice)) {
                const amount = (qty * (unitPrice + (unitPrice / 10)));
                setValue(`salesagreement_items.${index}.amount`, amount);
            } else {
                setValue(`salesagreement_items.${index}.amount`, 0);
            }
        }, [qty, unitPrice, index, setValue]);
        return (
            <Controller
                control={control}
                name={`salesagreement_items.${index}.amount`}
                render={() => value?.[index]?.qty && value?.[index]?.unit_price != 0 ? 
                <><Input background="gray.100" textAlign="center" variant=""  value={getValues(`salesagreement_items.${index}.amount`)} {...register(`salesagreement_items.${index}.amount` as const, { required: true, valueAsNumber: true, })} type="number" defaultValue={qitem?.[index]?.amount} isReadOnly   fontSize={{base: "10px", lg: "12px"}}/></>
                : 
                <></>
                }
            />
        );
    };
    let pmethod = watch("payment_method"); // you can supply default value as second argument
    let pterm= watch("payment_term"); // you can supply default value as second argument
    let pdate= watch("payment_date"); // you can supply default value as second argument

    const { data: satncs } = useQuery<ISATNC[]>(["satncs"], getSATNC);
    const { fields, append, remove } = useFieldArray({
        name: "salesagreement_items", // unique name for your Field Array
        control,// control props comes from useForm (optional: if you are using FormContext)
        rules: {
            required: "Please append at least 1 item",
        }

    });
    const { fields: payFields, append: payAppend, remove: payRemove } = useFieldArray({
        name: "salesagreement_pays", // unique name for your Field Array
        control,// control props comes from useForm (optional: if you are using FormContext)
        rules: {
            required: "Please append at least 1 item",
        }

    });
    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation(uploadSalesAgreement, {
        onError: (error) => {
            console.log(error);
        },
        onSuccess: (data: ISalesAgreementDetail) => {

            toast({
            status: "success",
            title: "Sales Agreement Created",
            position: "bottom-right",
            });
            navigate(`/salesagreements/${data.id}`);
        },
    });

    useSalesOnlyPage();
    const onSubmit = (data: IUploadSalesAgreementVariables) => {
        mutation.mutate(data);
    }
    return (
        <ProtectedPage>
            <Box
            padding={20}
            backgroundColor='#292929'
            color="#fff"
            pb="16">
                <Box textAlign="center" fontSize="20">SALES AGREEMENT</Box>
                
            </Box>
            
            
            <Box display="flex" justifyContent="space-between" mb="20" mt="20px">
                    <Box><Link to={`/quotations/`}><Button colorScheme='facebook' variant='outline' size={{base: "xs", lg: "lg"}}>&#x2190; 견적서 리스트</Button></Link></Box>
                    <Box display={{base: "flex", lg: "none"}}>
                    <Button type="button" 
                    onClick={() => {
                        setValue("kind", qckind);
                        setValue("client.name", qcdentist);
                        setValue("client.clinic", qcclinic);
                        setValue("client.address", qcaddress);
                        setValue("client.email", qcemail);
                        setValue("client.mobile", qcmobile);
                        setValue("client.abn", qcabn);
                        setValue("special_condition", qspecialcondition);
                        resetField( "salesagreement_items", { defaultValue: qitem })
                    }}
                    textAlign="center" display={{base: "flex", lg: "none"}}
                    size={{base: "xs", lg: "lg"}}>
                        견적서 정보 불러오기에욤
                    </Button>
                    </Box>
                    <Box><Link to={`/quotations/${quotationPk}`}><Button colorScheme='facebook' variant='outline' size={{base: "xs", lg: "lg"}}>견적서 보기 &#x2192;</Button></Link></Box>
            </Box>
            <Box><SearchProduct></SearchProduct></Box>


            
            {/* 
            ***
            1. Client Information
            ***
            */}
            
            <Box
                pb={40}
                mt={10}
                px={{  
                    base: "20px",
                    lg: "10vw",
                }}
            >
                
                <Box mb={{base: "50px", lg: "100px"}}>
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall'>
                <Highlight
                    query='고객에 대한 정보'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    1.  계약서를 작성 할 고객에 대한 정보를 입력해주세요
                </Highlight>
                <Button ml="20px" type="button" 
                    onClick={() => {
                        setValue("kind", qckind);
                        setValue("client.name", qcdentist);
                        setValue("client.clinic", qcclinic);
                        setValue("client.address", qcaddress);
                        setValue("client.email", qcemail);
                        setValue("client.mobile", qcmobile);
                        setValue("client.abn", qcabn);
                        setValue("special_condition", qspecialcondition);
                        resetField( "salesagreement_items", { defaultValue: qitem })
                    }}
                    textAlign="center" display={{base: "none", lg: "inline-block"}}>
                        견적서 정보 불러오기
                </Button>
                </Heading>

                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px">1.</Text><Text display="inline-block" textDecoration="underline">CLIENT INFORMATION</Text></Box> */}
                
                
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Dentist Name:</FormLabel>
                            <Input w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.name", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Dentist Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Name:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.clinic", { required: true })} type="text" pl={{base: "10px", lg:"20px"}} placeholder="Clinic Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Address:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.address", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client Address" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Mobile:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.mobile", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client Mobile" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Email:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.email", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client Email" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                            </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic ABN:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.abn", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client ABN" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                    </FormControl>
                </FormControl>
                </Box>

                {/*
                ***
                2. Payment Term
                ***
                */}
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query={['결제기간', '결제날짜']}
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    2.  계약서의 결제기간 & 결제날짜를 입력해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">2.</Text><Text display="inline-block" textDecoration="underline">PAYMENT TERM</Text><Text id="payment" display="inline-block"><Text w={{base: "100%", lg: "150px"}} display="inline-block" ml="30px">총 결제금액: $ </Text><Box display="inline-block"><Payment control={control}/></Box></Text></Box> */}

                <FormControl display={{base:"block", lg:"flex"}}>
                    <FormControl  pt={{base: "30px", lg:"50px"}} pb="30px" pl="30px" pr="30px">
                        <FormControl>


                            
                            {/* <Text ml="50px" mb="20px" fontSize="xl">결제방법</Text> */}
                            <Box mb="30px" display="inline-block">
                            <Box display="inline-block" textAlign={{base: "left", lg: "right"}} className="radio"> 
                            <Box className="radio-toolbar">
                            <input id="lump sum" type="radio" {...register("payment_term")} value="Lump Sum" /> 
                            <FormLabel htmlFor="lump sum" fontSize={{base: "10px", lg:"14px"}}>Lump Sum</FormLabel>
                            </Box>
                            </Box>
                            <Box display="inline-block"  > 
                            <Box className="radio-toolbar">
                            <input id="installment" type="radio" {...register("payment_term")} value="Installment" />
                            <FormLabel htmlFor="installment" fontSize={{base: "10px", lg:"14px"}}>Installment</FormLabel>
                            </Box>
                            </Box>
                            </Box>
                            {pterm == "Lump Sum" && (
                                <>
                                {payFields.map((field,index)=>(
                                    <FormControl key={field.id} pb="10px">
                                        
                                        <HStack>
                                        <FormControl>
                                            <InputGroup size="sm">
                                            <Input {...register(`salesagreement_pays.${index}.price` as const, { required: true, })} type="number" textAlign="center" placeholder="ex) 20000" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}variant='flushed' />
                                            </InputGroup>
                                        </FormControl>
                                        <Text>x</Text>
                                        <FormControl>
                                            <InputGroup size="sm">
                                            <Input {...register(`salesagreement_pays.${index}.amount` as const, { required: true,valueAsNumber: true,})} type="number"  textAlign="center" placeholder="ex) 1"  _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}} variant='flushed' />
                                            </InputGroup>
                                        </FormControl>
                                        
                                        <HStack>
                                        <IconButton
                                            size="sm"
                                            aria-label="open menu"
                                            variant="clear"
                                        />
                                        <IconButton
                                            size="sm"
                                            aria-label="open menu"
                                            variant="clear"
                                        />
                                        </HStack>
                                        </HStack>
                                    </FormControl>
                                    ))}
                                </>
                            )}
                            {pterm == "Installment" && (
                                <>
                                {payFields.map((field,index)=>(
                                <FormControl key={field.id} pb="10px">
                                    <HStack>
                                    <FormControl>
                                        <InputGroup size="sm">
                                        <Input {...register(`salesagreement_pays.${index}.price` as const, { required: true, })} type="number"  placeholder="ex) 20000" textAlign="center" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}} variant='flushed' />
                                        </InputGroup>
                                    </FormControl>
                                    <Text>x</Text>
                                    <FormControl>
                                        <InputGroup size="sm">
                                        <Input {...register(`salesagreement_pays.${index}.amount` as const, { required: true,valueAsNumber: true,})} type="number"  textAlign="center" placeholder="ex) 1" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}} variant='flushed' />
                                        </InputGroup>
                                    </FormControl>
                                    
                                    <HStack>
                                    <IconButton
                                        size="sm"
                                        aria-label="open menu"
                                        icon={<AiOutlinePlus />}
                                        onClick={() =>
                                            payAppend({
                                                price: 0,
                                                amount: 0,
                                            })
                                        }
                                        variant="outline"
                                    />
                                    <IconButton
                                        size="sm"
                                        aria-label="open menu"
                                        icon={<AiOutlineMinus />}
                                        onClick={() => payRemove(index)}
                                        variant="outline"

                                    />
                                    </HStack>
                                    </HStack>
                                </FormControl>
                                ))}
                                </>
                            )}

                        </FormControl>
                    </FormControl>
                    <FormControl  pt={{base: "30px", lg:"50px"}} pb="30px" pl="30px" pr="30px">
                        <FormControl>
                            {/* <Text mb="20px" fontSize="xl">결제날짜</Text> */}

                            <Box mb="30px" display="inline-block">
                            <Box display="inline-block"> 
                            <Box className="radio-toolbar">
                            <input id="on" type="radio" {...register("payment_date")} value="Commence On" /> 
                            <FormLabel htmlFor="on" fontSize={{base: "10px", lg:"14px"}}>Commences on</FormLabel>
                            </Box>
                            </Box>
                            <Box display="inline-block"> 
                            <Box className="radio-toolbar">
                            <input id="upon" type="radio" {...register("payment_date")} value="Arrival Of Invoice" /> 
                            <FormLabel htmlFor="upon" fontSize={{base: "10px", lg:"14px"}}>Arrival of invoice</FormLabel>
                            </Box>
                            </Box>
                            </Box>                    
                            {pdate == "Commence On" && (
                            <>
                            <FormControl>
                                <HStack>
                                <FormControl>
                                    <HStack>
                                    <InputGroup size="sm">
                                    <Input {...register("payday")} type="text" variant='flushed' textAlign="center" placeholder="Day" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                    </InputGroup>
                                    <InputGroup size="sm">
                                    <Select {...register("paymonth")}
                                        variant='flushed' textAlign="center" placeholder="Month" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}
                                    >
                                        <option value="Jan">January</option>
                                        <option value="Feb">February</option>
                                        <option value="Mar">March</option>
                                        <option value="Apr">April</option>
                                        <option value="May">May</option>
                                        <option value="Jun">June</option>
                                        <option value="Jul">July</option>
                                        <option value="Aug">August</option>
                                        <option value="Sep">September</option>
                                        <option value="Oct">October</option>
                                        <option value="Nov">November</option>
                                        <option value="Dec">December</option>
                                    </Select>
                                    </InputGroup>
                                    <InputGroup size="sm">
                                    <Input {...register("payyear")} type="text" variant='flushed' textAlign="center" placeholder="Year" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                    </InputGroup>
                                    </HStack>
                                </FormControl>
                                
                                </HStack>
                            </FormControl>
                            </>
                            )}            
                            
                        </FormControl>
                    </FormControl>
                </FormControl>
                </Box>

                {/* <Box p="5">
                    <Box pos="relative">
                        <FormControl isRequired colorScheme="green">
                        <InputGroup>
                            <Input
                            {...register("payment_term")}
                            p="5"
                            outline="none"
                            onFocus={() => setIsActive(true)}
                            onBlur={() =>
                                value === "" ? setIsActive(false) : setIsActive(true)
                            }
                            value={value}
                            onChange={handleChange}
                            />
                        </InputGroup>
                        </FormControl>
                        <Text
                        top={isActive ? "0%" : "50%"}
                        left={isActive ? "5px" : "50%"}
                        transform={
                            isActive
                            ? "translate(10px,-45%) scale(0.8)"
                            : "translate(-50%,-50%) scale(1)"
                        }
                        p="0 12px"
                        bg="#fff"
                        transformOrigin="top left"
                        transition="all .2s ease-out"
                        color="#999"
                        pointerEvents="none"
                        pos="absolute"
                        w="fit-content"
                        h="fit-content"
                        zIndex="5"
                        >
                        Phone
                        </Text>
                    </Box>
                </Box> */}
                {/* 
                ***
                3. Payment Method
                ***
                */}
                <Box mb={{base: "50px", lg: "100px"}}>
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='결제방법'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    3.  고객이 계약 금액을 지불 할 결제방법을 입력해주세요
                </Highlight>
                </Heading>
                                            
                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">3.</Text><Text display="inline-block" textDecoration="underline">PAYMENT METHOD</Text></Box> */}
                <FormControl display="flex">
                    <FormControl pt={{base: "30px", lg:"50px"}} pb="30px" pl="30px" pr="30px">
                        <FormControl>
                            <Box display="inline-block" mb="20px">
                                <Box display="inline-block"   textAlign={{base: "left", lg: "right"}}> 
                                <Box className="radio-toolbar">
                                <input id="credit_card" type="radio" {...register("payment_method")} value="Credit Card" /> 
                                <FormLabel htmlFor="credit_card" fontSize={{base: "10px", lg:"14px"}}>Credit Card</FormLabel>
                                </Box>
                                </Box>
                                <Box display="inline-block"  textAlign={{base: "left", lg: "right"}} > 
                                <Box className="radio-toolbar">
                                <input id="transfer" type="radio" {...register("payment_method")} value="Transfer" /> 
                                <FormLabel htmlFor="transfer" fontSize={{base: "10px", lg:"14px"}}>Transfer</FormLabel>
                                </Box>
                                </Box>
                                <Box display="inline-block"  textAlign={{base: "left", lg: "right"}} > 
                                <Box className="radio-toolbar">
                                <input id="cheque" type="radio" {...register("payment_method")} value="Cheque" /> 
                                <FormLabel htmlFor="cheque" fontSize={{base: "10px", lg:"14px"}}>Cheque</FormLabel>
                                </Box>
                                </Box>
                                <Box display="inline-block" textAlign={{base: "left", lg: "right"}}> 
                                    <Box className="radio-toolbar">
                                    <input id="loan" type="radio" {...register("payment_method")} value="Loan"/>
                                    <FormLabel htmlFor="loan" fontSize={{base: "10px", lg:"14px"}}>Loan</FormLabel>
                                    </Box>
                                </Box>
                            </Box>
                            {pmethod == "Credit Card" && (
                            <>
                            <FormControl p={{base:"0px",lg:"0px"}}>
                            <FormControl background="#fafafa" p="30px" w="100%">
                                <FormControl display="inline-block" >
                                    <Box display="block" mb="30px">
                                        <Box className="radio-toolbar" display="inline-block">
                                            <Box display="inline-block"> 
                                            <input id="visa" type="radio" {...register("credit_type")} value="Visa" /> 
                                            <label htmlFor="visa">Visa</label>
                                            </Box>
                                        </Box>
                                        <Box className="radio-toolbar" display="inline-block">
                                            <Box display="inline-block"> 
                                            <input id="master" type="radio" {...register("credit_type")} value="Master" /> 
                                            <label htmlFor="master">Master</label>
                                            </Box>
                                        </Box>
                                        <Box className="radio-toolbar" display="inline-block">
                                            <Box display="inline-block"  > 
                                            <input id="american" type="radio" {...register("credit_type")} value="American Express" /> 
                                            <label htmlFor="american">American Express</label>
                                            </Box>
                                        </Box>
                                    </Box>
                                </FormControl>  
                                <FormControl>
                                    <FormLabel w={{base: "100%", lg: "100px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Card No</FormLabel>
                                    <Input w={{base: "100%", lg: "calc(90% - 100px)"}} variant='flushed' {...register("credit_no", { required: true })} type="text"         borderColor="gray.400" placeholder="ex) OOOO OOOO OOOO OOOO or OOOO OOOOOO OOOOO"  pl={{base: "10px", lg:"20px"}} _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                </FormControl>  

                                <FormControl>
                                    <HStack>
                                        <FormControl>
                                        <HStack>
                                            <FormLabel w={{base: "100%", lg: "100px"}} display={{base:"block", lg:"inline-block"}} textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}}fontSize={{base: "10px", lg:"14px"}}>Expire Date</FormLabel>
                                            {/* <Input  w={{base: "100%", lg: "150px"}} variant='flushed' {...register("credit_exmonth", { required: true })} type="text" textAlign="center" placeholder="ex) 01" borderColor="gray.400"/>  */}
                                            <Select {...register("credit_exmonth")}
                                                placeholder="Month"
                                                w={{base: "100%", lg: "100px"}}
                                                variant='flushed'
                                                textAlign="center"
                                                fontSize="sm"
                                            >
                                                <option value="Jan">January</option>
                                                <option value="Feb">February</option>
                                                <option value="Mar">March</option>
                                                <option value="Apr">April</option>
                                                <option value="May">May</option>
                                                <option value="Jun">June</option>
                                                <option value="Jul">July</option>
                                                <option value="Aug">August</option>
                                                <option value="Sep">September</option>
                                                <option value="Oct">October</option>
                                                <option value="Nov">November</option>
                                                <option value="Dec">December</option>
                                            </Select>
                                            <Text>/</Text>
                                            <Input w={{base: "100%", lg: "100px"}} variant='flushed' {...register("credit_exyear", { required: true })} type="text"  textAlign="center" placeholder="ex) 1234" borderColor="gray.400" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                        </HStack>
                                    </FormControl>
                                    </HStack>
                                </FormControl> 
                                <FormControl>
                                    <FormLabel w={{base: "100%", lg: "100px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}}fontSize={{base: "10px", lg:"14px"}}>CVV</FormLabel>
                                    <Input w={{base: "100%", lg: "calc(90% - 100px)"}} variant='flushed' {...register("credit_cvv", { required: true })} type="text" placeholder="ex) OOO or OOOO"borderColor="gray.400" pl={{base: "10px", lg:"20px"}} _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                </FormControl>  
                                
                            </FormControl>
                            </FormControl>
                            </>
                            )}
                            
                            {pmethod == "Loan" && (
                            <>
                            <FormControl p={{base:"0px",lg:"0px"}}pt="20px">
                            <FormControl background="#fafafa" p="30px" w="100%">
                                
                                <FormControl>
                                    <FormLabel w={{base: "100%", lg: "100px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Dentist Name</FormLabel>
                                    <Input w={{base: "100%", lg: "calc(90% - 100px)"}} variant='flushed' {...register("client.name", { required: true })} type="text" borderColor="gray.400" placeholder="ex) Lionel Messi" pl={{base: "10px", lg:"20px"}} _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                </FormControl>  
                                <FormControl>
                                    <FormLabel w={{base: "100%", lg: "100px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Loan Company</FormLabel>
                                    <Input w={{base: "100%", lg: "calc(90% - 100px)"}} variant='flushed' {...register("loan_company", { required: true })} type="text" borderColor="gray.400" placeholder="ex) Paris Saint Germain" pl={{base: "10px", lg:"20px"}} _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel w={{base: "100%", lg: "100px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Rep</FormLabel>
                                    <Input w={{base: "100%", lg: "calc(90% - 100px)"}} variant='flushed' {...register("loan_rep", { required: true })} type="text" borderColor="gray.400" placeholder="ex) Rep Name" pl={{base: "10px", lg:"20px"}} _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                </FormControl> 
                                <FormControl>
                                    <FormLabel w={{base: "100%", lg: "100px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Mobile</FormLabel>
                                    <Input w={{base: "100%", lg: "calc(90% - 100px)"}} variant='flushed' {...register("loan_mobile", { required: true })} type="text" borderColor="gray.400" placeholder="ex) 04xx xxx xxx" pl={{base: "10px", lg:"20px"}} _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                </FormControl> 
                                <FormControl>
                                    <FormLabel w={{base: "100%", lg: "100px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Email</FormLabel>
                                    <Input w={{base: "100%", lg: "calc(90% - 100px)"}} variant='flushed' {...register("loan_email", { required: true })} type="text" borderColor="gray.400"placeholder="ex) example@gmail.com" pl={{base: "10px", lg:"20px"}} _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                                </FormControl> 
                            </FormControl>
                            </FormControl>
                            
                            </>
                            )}
                        </FormControl>            
                    </FormControl>
                </FormControl>
                </Box>
                
                {/* 
                ***
                4. Sales Agreement Items
                ***
                */}

                <Box mb={{base: "50px", lg: "100px"}}>
            

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='계약제품'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    4.  계약서에 들어가는 계약제품을 입력해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">4.</Text><Text display="inline-block" textDecoration="underline">LIST OF PRODUCTS</Text></Box> */}
                    <FormControl p={{base:"0px", lg:"30px"}} pt="30px">
                        <FormControl background="#414042" color="#fff" pt="10x" pb="0px">
                            <HStack>
                                <FormControl w={{base: "5%", lg:"5%"}}>
                                        <FormLabel textAlign="center" mr="0" fontSize={{base: "10px", lg: "14px"}}>No</FormLabel>
                                </FormControl>
                                <FormControl  w={{base: "40%", lg:"50%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Description</FormLabel>
                                </FormControl>
                                <FormControl w={{base: "10%", lg:"10%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Qty</FormLabel>
                                </FormControl>
                                <FormControl w={{base: "20%", lg:"15%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Unit Price</FormLabel>
                                </FormControl>
                                <FormControl w={{base: "25%", lg:"20%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Amount</FormLabel>
                                </FormControl>
                                <HStack>
                                <IconButton
                                    aria-label="open menu"
                                    variant="clear"
                                />
                                <IconButton
                                    aria-label="open menu"
                                    variant="clear"
                                />
                                </HStack>
                            </HStack>
                        </FormControl>

                        {fields.map((field,index)=>(
                        <FormControl key={field.id} mt="7px" mb="7px">
                            <HStack>
                            <FormControl w={{base: "5%", lg:"5%"}}>
                                <Text textAlign="center" fontSize={{base: "10px", lg: "12px"}}>{index+1}</Text>
                            </FormControl>
                            <FormControl w={{base: "40%", lg:"50%"}}>
                                <Input textAlign="center" variant='flushed'{...register(`salesagreement_items.${index}.description` as const, { required: true, })} type="text"  fontSize={{base: "10px", lg: "12px"}}/>
                            </FormControl>
                            <FormControl w={{base: "5%", lg:"10%"}}>
                                <Input textAlign="center" variant='flushed'{...register(`salesagreement_items.${index}.qty` as const, { required: true,valueAsNumber: true,})} type="number"  fontSize={{base: "10px", lg: "12px"}}/>
                            </FormControl>
                            <FormControl w={{base: "20%", lg:"15%"}}>
                                <HStack>

                                <Input textAlign="center" variant='flushed'{...register(`salesagreement_items.${index}.unit_price` as const, { required: true, valueAsNumber: true,})} type="number"  fontSize={{base: "10px", lg: "12px"}}/>
                                <IconButton 
                                    aria-label="Divide 1.1"
                                    icon={<FaDivide />}
                                    onClick={() => {
                                        setValue(`salesagreement_items.${index}.unit_price`, getValues(`salesagreement_items.${index}.unit_price`) / 1.1);
                                    }}
                                    cursor="pointer"
                                    fontSize={{base: "12px", lg: "14px"}}
                                    color="blue.500"
                                    size={{base:"xs", lg:"xs"}}

                                >
                                    나누기 1.1
                                </IconButton>
                                </HStack>
                            </FormControl>
                            <FormControl w={{base: "25%", lg:"20%"}}>
                                {<Amount control={control}/>}
                                <ConditionalInput {...{ control, index }} />
                            </FormControl>
    
                            <HStack>
                            <IconButton
                                aria-label="open menu"
                                icon={<AiOutlinePlus />}
                                onClick={() =>
                                    append({
                                        description: "",
                                        qty: 0,
                                        unit_price: 0,
                                        amount: 0
                                    })
                                }
                                size={{base:"xs", lg:"md"}}
                                variant='outline'

                            />
                            <IconButton
                                aria-label="open menu"
                                icon={<AiOutlineMinus />}
                                onClick={() => remove(index)}
                                size={{base:"xs", lg:"md"}}
                                variant='outline'

                            />
                            </HStack>
                            </HStack>
                        </FormControl>
                        ))}

                        <Box display="flex" justifyContent="space-between"  mt="40px">
                            <Box></Box>
                            <Box>
                                <HStack borderTop="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="total_price"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Total Price:</b> $</Box><Box><TotalPrice control={control} /></Box></HStack>
                                <HStack borderTop="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="gst_total"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Gst:</b> $</Box><Box><Gst control={control} /></Box></HStack>
                                <HStack borderTop="1px solid #f0f0f0" borderBottom="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="final_price"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Final Price:</b> $</Box><Box><FinalPrice control={control}/></Box></HStack>
                            </Box>
                        </Box>
                        
                    </FormControl>
                        <p>{errors.salesagreement_items?.root?.message}</p>
                
                </Box>
                
                {/* 
                ***
                5. Sales Agreement Special Conditions
                ***
                */}
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='특이사항'
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    5.  계약서 특이사항을 입력해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Special Condition</FormLabel>
                                <Textarea {...register("special_condition", { required: true })}  />
                        </FormControl>
                </Box>


                {/* 
                ***
                6. Sales Agreement Terms & Conditions
                ***
                */}
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query={['Terms', 'Conditions']}
                    styles={{ px: '2', py: '1', borderRadius:"5px", bg: 'gray.100' }}
                >
                    6.  계약에 맞는 Terms & Conditions을 선택해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">6.</Text><Text display="inline-block" textDecoration="underline">TERMS & CONDITION</Text></Box> */}

                <Box>
                    <VStack spacing={15} as="form" onSubmit={handleSubmit(onSubmit)} mt={5}>
                    
                    {/* <FormControl>
                        <FormLabel>Quotation Category </FormLabel>
                        <Select {...register("kind", { required: true })}
                            placeholder="Choose a kind"
                        >
                            <option value="Implant">Implant</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Seminar">Seminar</option>
                        </Select>
                    </FormControl> */}



                    <p>{errors.salesagreement_items?.root?.message}</p>

                    
                    
                    <FormControl p="30px" pt="20px" mb="100px">
                            <Grid templateColumns={"1fr 1fr 1fr"} gap={5}>
                                {satncs?.map((satnc,index) => (
                                <Box key={satnc.id}>
                                    <Checkbox value={satnc.id}  {...register("satncs", { required: true })}>{satnc.name}</Checkbox>
                                </Box>
                                ))}
                            </Grid>
                    </FormControl>
                    
                    {mutation.isError ? (
                        <Text color="red.500">Something went wrong</Text>
                    ) : null}
                    <Button 
                    type="submit"
                    isLoading={mutation.isLoading}
                    colorScheme="facebook"
                    size="lg"
                    w={{base: "100%", lg: "200px"}}
                    >
                    계약서 생성하기
                    </Button>
                    </VStack>
                </Box>
            </Box>
        </ProtectedPage>
    )
}
