import { Box, Image, Text } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import manual_login from "../components/img/manual_login.png"

export default function Howtouse() {
  return (
    <>

    
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'black', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              [로그인페이지] 

            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        <a href="/login" target="_blank" >mmximp.com.au/login</a> <br/><br/>
          회사 내부에서 제공 된 아이디 & 비밀번호를 사용해 웹사이트에 로그인을 합니다.
          <Image src={manual_login} w="600px"></Image>
        </AccordionPanel>
      </AccordionItem>
      {/* */}
      {/* 메인페이지 */}
      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'black', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              [메인페이지] 

            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        <a href="/" target="_blank">mmximp.com.au/</a> <br/><br/>
        이 페이지는 유저의 롤에 맞게 추후에 여러가지 편의기능 (예시 - 1월 견적서 총 개수, 계약서 총 개수)
        같은 내용들이 커스텀 추가 될 예정입니다.
        </AccordionPanel>
      </AccordionItem>
      
      {/* 게시판페이지 */}
      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'black', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              [게시판]

            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        <a href="/boards" target="_blank">mmximp.com.au/boards</a> <br/><br/>
          이 페이지는 경우, 직원들이 자유롭게 글을 올릴 수 있고, 회사에서 내리는 공지사항이 올라 올 수 있습니다. <br/>
          현재는 제목, 내용의 간단한 정보만 입력이 가능한데, 추후에 사진업로드, 꾸밀 수 있는 기능이 있는 툴이 추가 될 예상을 하고 있습니다. <br/>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'black', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              [견적서] 
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: 'pink', color: 'white' }}>
              <Box as="span" flex='1' textAlign='left'>
              견적서리스트
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
          <a href="/quotations" target="_blank">mmximp.com.au/quotations</a><br/><br/>
          - 견적서 서치<br/>
          - 견적서만들기 버튼<br/>
          - 견적서데이타<br/>
          - 견적서아이디 버튼<br/>
          - 견적서를 바탕으로 계약서 만들기 버튼<br/>

          <br/>현재 총 5개의 구성으로 이루어져 있습니다.<br/><br/>
          현재 자신이 올린 견적서리스트를 볼 수 있는 페이지이며, 각 견적서번호 버튼을 누를 시 자신이 만든 견적서의 디테일을 볼 수 있는 페이지로 이동됩니다. <br/><br/>

          - 견적서 서치<br/>
          현재 자신이 올린 견적서 중 특정한 견적서를 찾을 때 사용하는 서치 기능입니다. 찾고자 하는 견적서의 의사이름/클리닉/날짜 등을 소문자로 입력 시 찾고자 하는 견적서가 나옵니다. <br/><br/>
          - 견적서만들기 버튼<br/>
          견적서를 새로 만들 수 있는 페이지로 넘어갑니다. <br/><br/>
          - 견적서데이타<br/>
          현재 자신이 올린 견적서데이타입니다. 견적서아이디 / 견적서생성날짜 / 견적서생성자 / 견적서의사이름 / 견적서치과이름 / 최종견적가격 / 견적서카테고리 총 7개의 내용이 들어있습니다.<br/><br/>
          - 견적서아이디 버튼<br/>
          자신이 만든 특정 견적서의 세부내용을 볼 수 있는 버튼입니다. 클릭 시, 견적서 세부내용 페이지로 이동됩니다. <br/><br/>
          - 견적서를 바탕으로 계약서 만들기 버튼<br/>
          자신이 만든 특정 견적서 내용을 바탕으로 계약서를 만들 수 있는 페이지로 이동됩니다. <br/>
          </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: 'pink', color: 'white' }}>
              <Box as="span" flex='1' textAlign='left'>
              견적서 세부
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <a href="/quotations/1" target="_blank">mmximp.com.au/quotations/id</a><br/><br/>
            - 견적서리스트 버튼<br/>
            - 계약서만들기<br/>
            - 견적서 PDF 버튼<br/>
            - 견적서 세부내용<br/>

            <br/>현재 총 4개의 구성으로 이루어져 있습니다.<br/><br/>
            현재 자신이 올린 견적서 특정 데이타의 세부내용을 볼 수 있는 페이지이며, 해당견적서의 pdf 폼을 다운 받을 수 있습니다.<br/><br/>

            - 견적서리스트 버튼<br/>
            자신의 견적서리스트 페이지로 돌아갑니다 <br/><br/>
            - 계약서만들기<br/>
            현재 견적서 내용을 바탕으로 계약서를 만들 수 있는 페이지로 이동됩니다. <br/><br/>
            - 견적서 PDF 버튼<br/>
            현재 견적서 내용을 바탕으로 PDF 폼을 만들어줍니다.<br/><br/>
            - 견적서 세부내용<br/>
            자신이 만든 특정 견적서의 세부내용을 볼 수 있습니다. <br/><br/>
          </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: 'pink', color: 'white' }}>
              <Box as="span" flex='1' textAlign='left'>
              견적서 업로드
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <a href="/quotations/1" target="_blank">mmximp.com.au/quotations/id</a><br/><br/>
            견적서를 만들기 위해 견적서에 들어가는 내용들을 입력하는 페이지입니다.
            <br/><br/>
            0. 기본
            - 견적서 제목(내부에서 찾기 편하기위해)<br/>
            - 견적서 타입(Implant / Equipment / Seminar)<br/><br/>
            1. 고객정보<br/>
            - 의사이름<br/>
            - 클리닉이름<br/>
            - 클리닉주소<br/>
            - 전화번호<br/>
            - 이메일<br/>
            - ABN<br/>
            <br/>2. 견적제품<br/>
            - 견적제품 또는 아이템 설명<br/>
            - 수량<br/>
            - include gst가 포함된 가격<br/><br/>
            + / - 를 통해 추가/삭제를 할 수 있으며, 할인가의 경우 unit price에 마이너스 금액을 입력하시면 됩니다.<br/>
            <br/>3. 견적특이사항<br/>
            - 스페셜 컨디션<br/>
            <br/>4. 견적서 Terms & Conditions<br/>
            - Implant / Equipment / Seminar 중 원하는 것 선택(복수가능)<br/>
            <br/>
            모든 정보를 다 입력 후 견적서 생성 버튼을 누르면 견적서 세부 페이지로 이동합니다.
          </AccordionPanel>
          </AccordionItem>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'black', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              [계약서] 
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: 'pink', color: 'white' }}>
              <Box as="span" flex='1' textAlign='left'>
              계약서리스트
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
          <a href="/salesagreements" target="_blank">mmximp.com.au/salesagreements</a><br/><br/>
          - 계약서 서치<br/>
          - 계약서만들기 버튼<br/>
          - 계약서리스트<br/>
          - 계약서아이디 버튼<br/><br/>

          현재 총 4개의 구성으로 이루어져 있습니다.<br/>
          현재 자신이 올린 계약서리스트를 볼 수 있는 페이지이며, 각 계약서번호 버튼을 누를 시 자신이 만든 계약서의 디테일을 볼 수 있는 페이지로 이동됩니다. <br/><br/>

          - 계약서 서치<br/>
          현재 자신이 올린 계약서 중 특정한 계약서를 찾을 때 사용하는 서치 기능입니다. 찾고자 하는 계약서의 의사이름/클리닉/날짜 등을 소문자로 입력 시 찾고자 하는 계약서가 나옵니다. <br/><br/>
          - 계약서만들기 버튼<br/>
          계약서를 새로 만들 수 있는 페이지로 넘어갑니다. <br/><br/>
          - 계약서데이타<br/>
          현재 자신이 올린 계약서데이타입니다. 계약서아이디 / 계약서생성날짜 / 계약서생성자 / 계약서의사이름 / 계약서치과이름 / 최종계약가격 / 계약서카테고리 총 7개의 내용이 들어있습니다.<br/><br/>
          - 계약서아이디 버튼<br/>
          자신이 만든 특정 계약서의 세부내용을 볼 수 있는 버튼입니다. 클릭 시, 계약서 세부내용 페이지로 이동됩니다. <br/><br/>
          
          </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: 'pink', color: 'white' }}>
              <Box as="span" flex='1' textAlign='left'>
              계약서 세부
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <a href="/salesagreements/1" target="_blank">mmximp.com.au/salesagreements/id</a><br/><br/>
            - 계약서리스트 버튼<br/>
            - 계약서 PDF 버튼<br/>
            - 계약서 세부내용<br/>

            <br/>현재 총 3개의 구성으로 이루어져 있습니다.<br/>
            <br/>현재 자신이 올린 견적서 특정 데이타의 세부내용을 볼 수 있는 페이지이며, 해당견적서의 pdf 폼을 다운 받을 수 있습니다.<br/><br/>

            - 계약서리스트 버튼<br/>
            자신의 계약서리스트 페이지로 돌아갑니다 <br/><br/>
            - 계약서 PDF 버튼<br/>
            현재 계약서 내용을 바탕으로 PDF 폼을 만들어줍니다.<br/><br/>
            - 계약서 세부내용<br/>
            자신이 만든 특정 계약서의 세부내용을 볼 수 있습니다. <br/><br/>
          </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: 'pink', color: 'white' }}>
              <Box as="span" flex='1' textAlign='left'>
              계약서 업로드
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <a href="/quotations/1" target="_blank">mmximp.com.au/quotations/id</a><br/><br/>
            견적서를 만들기 위해 견적서에 들어가는 내용들을 입력하는 페이지입니다.
            <br/><br/>
            
            1. 고객정보<br/>
            - 의사이름<br/>
            - 클리닉이름<br/>
            - 클리닉주소<br/>
            - 전화번호<br/>
            - 이메일<br/>
            - ABN<br/>
            <br/>2. 계약금 결제기간 & 결제날짜<br/>
            - 기간(일시불 / 할부)<br/>
            - 날짜(인보이스날짜 / 특정날짜)<br/>
            <br/>3. 계약금 결제방법<br/>
            - 방법(크레딧카드 / 트랜스퍼 / 체크 / 론회사)<br/>
            <br/>크레딧카드의 경우 <br/>
            - 카드타입 <br/>
            - 카드번호 <br/>
            - expire date <br/>
            - cvv <br/>
            <br/>트랜스퍼/체크의 경우 <br/>
            - 체크만하면 나중에 계약서폼에 자동으로 회사 account 정보 입력<br/>
            <br/>론회사의 경우 <br/>
            - 의사이름<br/>
            - 론회사이름 <br/>
            - 론회사세일즈 <br/>
            - 론회사전화번호 <br/>
            - 론회사이메일 <br/>
            <br/>4. 계약제품<br/>
            - 계약제품 또는 아이템 설명<br/>
            - 수량<br/>
            - include gst가 포함된 가격<br/><br/>
            + / - 를 통해 추가/삭제를 할 수 있으며, 할인가의 경우 unit price에 마이너스 금액을 입력하시면 됩니다.<br/>
            <br/>5. 계약특이사항<br/>
            - 스페셜 컨디션<br/>
            <br/>6. 계약서 Terms & Conditions<br/>
            - Implant / Equipment / Seminar 중 원하는 것 선택(복수가능)<br/>
            <br/>
            모든 정보를 다 입력 후 견적서 생성 버튼을 누르면 계약서 세부 페이지로 이동합니다.
          </AccordionPanel>
          </AccordionItem>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    <Box>
    


    </Box>
    </>
  );
}


