import { Badge, Button, Td, Tr  } from "@chakra-ui/react";
import { VscFilePdf } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
interface IBoardProp {
    id: number;
    board_id: string;
    submission_date: string;
    title: string;
    description: string;
    submitter: string;
    

}
export default function Boards({
    id,
    board_id,
    submission_date,
    title,
    description,
    submitter,
}: IBoardProp) {
    const navigate = useNavigate();
    
    return (
        <>            
            <Tr>
                <Td><Link to={`/boards/${id}`}><Button size="xs" colorScheme="gray">{board_id}</Button></Link></Td>
                <Td fontSize="xs">{submission_date}</Td>
                <Td fontSize="xs" textAlign="center">{submitter}</Td>
                <Td fontSize="xs">{title}</Td>
            </Tr>

            
        </>

    )
}