import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getMe } from "../api";
import useUser from "../lib/useUser";
import { IUser } from "../types";

export default function useSalesOnlyPage(){
  const { user, userLoading } = useUser();
  const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserLoading) {
      if (userData?.role=="Admin" || userData?.role=="Ts" || userData?.role=="") {
        navigate("/");
      }
    }
  }, [isUserLoading, userData, navigate]);
  return; 
}