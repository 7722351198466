import {  Box, Button, Checkbox,  FormControl, FormHelperText, FormLabel, Grid, Heading, HStack, Input, Select, Textarea, useToast, VStack, Text,  IconButton, Highlight, Switch, Flex, useColorModeValue, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, InputGroup, InputLeftElement, Stack } from "@chakra-ui/react";
import { getProductDatas, IUploadSamplerequestVariables, uploadSamplerequest } from "../../api";
import ProtectedPage from "../../components/ProtectedPage";
import SearchProduct from '../../components/ProductData/SearchProduct';

import useSalesOnlyPage from "../../components/SalesOnlyPage";
import { IProductDataList, ISamplerequestDetail,  ISamplerequestItems} from "../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, Control, useWatch, useFieldArray, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { FaDivide } from "react-icons/fa6";
import { SearchIcon } from "@chakra-ui/icons";
import ProductDatas from "../../components/ProductData/ProductDatas";
import React from "react";

function getTotalPrice(payload: IUploadSamplerequestVariables['samplerequest_items']) {
    let total:any = 0;

    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))
        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100 / 11 * 10).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return total;
}

function getGst(payload: IUploadSamplerequestVariables['samplerequest_items']) {
    let total:any = 0;

    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))
        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100 / 11).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    return total;

}       

function getFinalTotal(payload: IUploadSamplerequestVariables['samplerequest_items']) {
    let total:any = 0;
    
    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))
        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return total;
}

function getAmount(payload: IUploadSamplerequestVariables['samplerequest_items']) {
    let total:any = 0;
    // for (const item of payload) {
    //     item.amount = ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*item.unit_price)
    //     total = item.amount
    // }
    // total = parseInt((Math.round(total * 100) / 100).toFixed(2));
    // return total

    let amount:any= 0;

    payload.forEach((data, index) => {
        amount = data.amount
        data.amount= ((Number.isNaN(data.qty)) || (Number.isNaN(data.unit_price)) ? 0 : data.qty*(data.unit_price + (data.unit_price/10)))
        amount = data.amount.toFixed(2)
        amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // amount = parseInt(`data.${index}.amount`)
        return amount;
    })
    
}

function getUnitPrice(payload: IUploadSamplerequestVariables['samplerequest_items']) {
    let total:any = 0;
    // for (const item of payload) {
    //     item.amount = ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*item.unit_price)
    //     total = item.amount
    // }
    // total = parseInt((Math.round(total * 100) / 100).toFixed(2));
    // return total

    let unitprice:any= 0;
    let isChecked:boolean = false;

    payload.forEach((data, index) => {
        unitprice = data.unit_price
        data.unit_price= ((Number.isNaN(data.unit_price) && isChecked) ? data.unit_price: data.unit_price/1.1)
        // amount = parseInt(`data.${index}.amount`)
        return unitprice;
    })
    
}
function UnitPrice({control}: {control: Control<IUploadSamplerequestVariables>}) {
    const values = useWatch({
        control,
        name: `samplerequest_items`,
    });
    // let amount:any= 0;
    // values.forEach((data, index) => {
    //     data.amount= data.unit_price * data.qty
    //     amount = parseInt(`data.${index}.amount`)
    //     return <>{data.amount}</>
    // })
    return <>{getUnitPrice(values)}</>
}
function Amount({control}: {control: Control<IUploadSamplerequestVariables>}) {
    const values = useWatch({
        control,
        name: `samplerequest_items`,
    });
    // let amount:any= 0;
    // values.forEach((data, index) => {
    //     data.amount= data.unit_price * data.qty
    //     amount = parseInt(`data.${index}.amount`)
    //     return <>{data.amount}</>
    // })
    return <>{getAmount(values)}</>
}
function TotalPrice({control}: {control: Control<IUploadSamplerequestVariables>}) {
    const values = useWatch({
        control,
        name: "samplerequest_items",
    });

    return <p>{getTotalPrice(values)}</p>
}
function Gst({control}: {control: Control<IUploadSamplerequestVariables>}) {
    const values = useWatch({
        control,
        name: "samplerequest_items",
    });

    return <p>{getGst(values)}</p>
}
function FinalPrice({control}: {control: Control<IUploadSamplerequestVariables>}) {
    const values = useWatch({
        control,
        name: "samplerequest_items",
    });
    return <p>{getFinalTotal(values)}</p>
}



export default function UploadSamplerequest() {



    const [isChecked, setIsChecked] = useState(false);
    const toggleSwitch = () => {
        setIsChecked(!isChecked);
    };
    const { control, register, handleSubmit, formState:{errors}, watch, setValue, getValues } = useForm<IUploadSamplerequestVariables>({
        defaultValues: {
            samplerequest_items: [{
            }],
        }
    });
    let kind= watch("kind"); // you can supply default value as second argument
    let demo_kind = watch("demo_kind"); // you can supply default value as third argument
    let demo_tested = watch("demo_tested"); // you can supply default value as third argument
    function ConditionalInput({ control, index}:{control: Control<IUploadSamplerequestVariables>, index: any}) {
        const value = useWatch({
            name: "samplerequest_items",
            control
        });
        const qty = useWatch({
            name: `samplerequest_items.${index}.qty`,
            control
        });
        const unitPrice = useWatch({
            name: `samplerequest_items.${index}.unit_price`,
            control
        });
        useEffect(() => {
            if (!isNaN(qty) && !isNaN(unitPrice)) {
                const amount = (qty * (unitPrice + (unitPrice / 10)));
                setValue(`samplerequest_items.${index}.amount`, amount);
            } else {
                setValue(`samplerequest_items.${index}.amount`, 0);
            }
        }, [qty, unitPrice, index, setValue]);
        return (
            <Controller
                control={control}
                name={`samplerequest_items.${index}.amount`}
                render={() => value?.[index]?.qty && value?.[index]?.unit_price !== 0 ? 
                <><Input mt="0" disabled value={getValues(`samplerequest_items.${index}.amount`)} {...register(`samplerequest_items.${index}.amount` as const, { required: true, valueAsNumber: true, })} type="number" isReadOnly /></>
                : 
                <></>
                }
            />
        );
    };
    const { fields, append, remove } = useFieldArray({
        name: "samplerequest_items", // unique name for your Field Array
        control,// control props comes from useForm (optional: if you are using FormContext)
        rules: {
            required: "Please append at least 1 item",
        }

    });
    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation(uploadSamplerequest, {
        onError: (error) => {
            console.log(error);
        },
        onSuccess: (data: ISamplerequestDetail) => {
            toast({
            status: "success",
            title: "Request Form Created",
            position: "bottom-right",
            });
            navigate(`/samplerequests/${data.id}`);
        },
    });
    useSalesOnlyPage();
    const onSubmit = (data: IUploadSamplerequestVariables) => {
        mutation.mutate(data);
    }
    return (
        <ProtectedPage>
            <Box
            padding={20}
            backgroundColor='#0765A3'
            color="#fff"
            pb="16">
                <Box textAlign="center" fontSize="20">CREATE REQUEST FORM</Box>
                
            </Box>
            
            <Box display="flex" justifyContent="space-between" mb="20" mt="20px">
                    <Box><Link to={`/samplerequests/`}><Button colorScheme='facebook' variant='outline'size={{base: "xs", lg: "lg"}}>&#x2190; Request Form 리스트</Button></Link></Box>
                    <Box><SearchProduct></SearchProduct></Box>

            </Box>

            <Box
                pb={40}
                mt={10}
                px={{  
                    base: "20px",
                    lg: "10vw",
                }}
            >
                <Box mb={{base: "50px", lg: "100px"}}>
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall'>
                <Highlight
                    query={['Request Type']}
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    0.  Request Type 을 적어주세요.
                </Highlight>
                </Heading>
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        
                        <FormControl>
                            <HStack>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Request Type:</FormLabel>
                            <Select {...register("kind", { required: true })}
                                fontSize="15px"
                                placeholder="Choose a kind"
                                w={{base: "150px", lg: "200px"}}
                                variant="flushed"
                                textAlign="center"
                            >
                                <option value="Sample">Sample</option>
                                <option value="Demo">Demo</option>
                                <option value="Draft Proposal">Draft Proposal</option>
                            </Select>
                            </HStack>
                        </FormControl>
                    </FormControl>
                </FormControl>
                </Box>
                {/*  0, 1 */}
                {kind == "Sample" && (
                <>
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall'>
                <Highlight
                    query='기본적인 사항'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    1.  Request 에 대한 기본적인 사항 을 적어주세요.
                </Highlight>
                </Heading>
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Dentist Name:</FormLabel>
                            <Input w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srdrname")} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Dentist Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Name:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srclinicname")} type="text" pl={{base: "10px", lg:"20px"}} placeholder="Clinic Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Ecount Code:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srcliniccode")} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Ecount Code" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Billing Email:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srbillingemail")} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Billing Email" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        
                    </FormControl>
                </FormControl>
                </Box>
                </>
                )}
                {kind == "Demo" && (
                <>
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall'>
                <Highlight
                    query='기본적인 사항'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    1.  Request 에 대한 기본적인 사항 을 적어주세요.
                </Highlight>
                </Heading>
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Dentist Name:</FormLabel>
                            <Input w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srdrname")} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Dentist Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Name:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srclinicname")} type="text" pl={{base: "10px", lg:"20px"}} placeholder="Clinic Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Ecount Code:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srcliniccode")} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Ecount Code" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Billing Email:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("srbillingemail")} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Billing Email" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        
                    </FormControl>
                </FormControl>
                </Box>
                </>
                )}
                {kind == "Draft Proposal" && (
                <>
                <Box mb={{base: "50px", lg: "100px"}}>

<Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
<Highlight
    query='기안서'
    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
>
    1.  기안서를 작성하는 이유를 적어주세요.
</Highlight>
</Heading>
{/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Special Condition</FormLabel>
                <Textarea {...register("description", { required: true })}  />
        </FormControl>
</Box>
</>
                )}


                

                
                        
                        {kind == "Sample" && (
                        <>
                        <Box mb={{base: "50px", lg: "100px"}}>

                        <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                        <Highlight
                            query='계약제품'
                            styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                        >
                            2.  Request 물품을 입력해주세요
                        </Highlight>
                        </Heading>
                        <FormControl p={{base:"0px", lg:"30px"}} pt="30px">

                        <FormControl background="#0765A3" color="#fff" pt="10x" pb="0px">
                            <HStack>
                                <FormControl w={{base: "5%", lg:"5%"}}>
                                        <FormLabel textAlign="center" mr="0" fontSize={{base: "10px", lg: "14px"}}>No</FormLabel>
                                </FormControl>
                                <FormControl  w={{base: "40%", lg:"50%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Item</FormLabel>
                                </FormControl>
                                <FormControl w={{base: "10%", lg:"10%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Qty</FormLabel>
                                </FormControl>
                                <FormControl w={{base: "20%", lg:"15%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Unit Price</FormLabel>
                                </FormControl>
                                <FormControl w={{base: "25%", lg:"20%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Amount</FormLabel>
                                </FormControl>
                                <HStack>
                                <IconButton
                                    aria-label="open menu"
                                    variant="clear"
                                />
                                <IconButton
                                    aria-label="open menu"
                                    variant="clear"
                                />
                                </HStack>
                            </HStack>
                        </FormControl>
                        {fields.map((field,index)=>(
                        <FormControl key={field.id} mt="7px" mb="7px">
                            <HStack>
                            <FormControl w={{base: "5%", lg:"5%"}}>
                                <Text  textAlign="center" fontSize={{base: "10px", lg: "12px"}}>{index+1}</Text>
                            </FormControl>
                            <FormControl w={{base: "40%", lg:"50%"}}>
                                <Input textAlign="center" variant='flushed'{...register(`samplerequest_items.${index}.item` as const)} type="text"  fontSize={{base: "10px", lg: "12px"}}/>
                            </FormControl>
                            <FormControl w={{base: "5%", lg:"10%"}}>
                                <Input textAlign="center" variant='flushed'{...register(`samplerequest_items.${index}.qty` as const, { valueAsNumber: true,})} type="number"  fontSize={{base: "10px", lg: "12px"}}/>
                            </FormControl>
                            <FormControl w={{base: "20%", lg:"15%"}}>
                                {/* {<UnitPrice control={control}/>} */}
                                {/* <HStack display="flex" align='center' direction='row' justifyContent="space-between">
                                <Text fontSize="10px">{isChecked ? "After Tax" : "Before Tax"}</Text><Switch isChecked={isChecked} onChange={toggleSwitch} size='sm' />
                                </HStack> */}
                                {/* <ConditionalInputUnitPrice {...{ control, index }} /> */}
                                <HStack>

                                <Input textAlign="center" variant='flushed'{...register(`samplerequest_items.${index}.unit_price` as const, { required: true, valueAsNumber: true,})} type="number"  fontSize={{base: "10px", lg: "12px"}}/>
                                <IconButton 
                                        aria-label="Divide 1.1"
                                        icon={<FaDivide />}
                                        onClick={() => {
                                            setValue(`samplerequest_items.${index}.unit_price`, getValues(`samplerequest_items.${index}.unit_price`) / 1.1);
                                        }}
                                        cursor="pointer"
                                        fontSize={{base: "12px", lg: "14px"}}
                                        color="blue.500"
                                        size={{base:"xs", lg:"xs"}}

                                    >
                                        나누기 1.1
                                    </IconButton>
                                </HStack>
                            </FormControl>
                            <FormControl w={{base: "25%", lg:"20%"}}>
                                {<Amount control={control}/>}
                                <ConditionalInput {...{ control, index }} />
                            </FormControl>
                            <HStack>
                            <IconButton
                                aria-label="open menu"
                                icon={<AiOutlinePlus />}
                                onClick={() =>
                                    append({
                                        item: "",
                                        qty: 0,
                                        unit_price: 0,
                                        amount: 0,
                                        serial_number: "",
                                    })
                                }
                                size={{base:"xs", lg:"md"}}
                                variant='outline'

                            />
                            <IconButton
                                aria-label="open menu"
                                icon={<AiOutlineMinus />}
                                onClick={() => remove(index)}
                                size={{base:"xs", lg:"md"}}
                                variant='outline'


                            />
                            </HStack>
                            </HStack>
                            
                        </FormControl>
                        
                        ))}
                        <Box display="flex" justifyContent="space-between"  mt="40px">
                                <Box></Box>
                                <Box>
                                    <HStack borderTop="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="total_price"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Total Price:</b> $</Box><Box><TotalPrice control={control} /></Box></HStack>
                                    <HStack borderTop="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="gst_total"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Gst:</b> $</Box><Box><Gst control={control} /></Box></HStack>
                                    <HStack borderTop="1px solid #f0f0f0" borderBottom="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="final_price"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Final Price:</b> $</Box><Box><FinalPrice control={control}/></Box></HStack>
                                </Box>
                            </Box>
                        </FormControl>
                        </Box>
                        </>
                        )}
                        {kind == "Demo" && (
                        <>
                        <Box mb={{base: "50px", lg: "100px"}}>

                        <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                        <Highlight
                            query='계약제품'
                            styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                        >
                            2.  Request 물품을 입력해주세요
                        </Highlight>
                        </Heading>
                        <FormControl p={{base:"0px", lg:"30px"}} pt="30px">

                        <FormControl background="#0765A3" color="#fff" pt="10x" pb="0px">
                            <HStack>
                                <FormControl w={{base: "5%", lg:"5%"}}>
                                        <FormLabel textAlign="center" mr="0" fontSize={{base: "10px", lg: "14px"}}>No</FormLabel>
                                </FormControl>
                                <FormControl  w={{base: "40%", lg:"70%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Item</FormLabel>
                                </FormControl>
                                <FormControl  w={{base: "40%", lg:"15%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Label</FormLabel>
                                </FormControl>
                                <FormControl w={{base: "10%", lg:"10%"}}>
                                        <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Qty</FormLabel>
                                </FormControl>
                                <HStack>
                                <IconButton
                                    aria-label="open menu"
                                    variant="clear"
                                />
                                <IconButton
                                    aria-label="open menu"
                                    variant="clear"
                                />
                                </HStack>
                            </HStack>
                        </FormControl>
                        {fields.map((field,index)=>(
                        <FormControl key={field.id} mt="7px" mb="7px">
                            <HStack>
                            <FormControl w={{base: "5%", lg:"5%"}}>
                                <Text  textAlign="center" fontSize={{base: "10px", lg: "12px"}}>{index+1}</Text>
                            </FormControl>
                            <FormControl w={{base: "40%", lg:"70%"}}>
                                <Input textAlign="center" variant='flushed'{...register(`samplerequest_items.${index}.item` as const)} type="text"  fontSize={{base: "10px", lg: "12px"}}/>
                            </FormControl>
                            <FormControl w={{base: "40%", lg:"15%"}}>
                                <Input textAlign="center" variant='flushed'{...register(`samplerequest_items.${index}.serial_number` as const, )} type="text"  fontSize={{base: "10px", lg: "12px"}}/>
                            </FormControl>
                            <FormControl w={{base: "5%", lg:"10%"}}>
                                <Input textAlign="center" variant='flushed'{...register(`samplerequest_items.${index}.qty` as const, { valueAsNumber: true,})} type="number"  fontSize={{base: "10px", lg: "12px"}}/>
                            </FormControl>
                            
                            
                            <HStack>
                            <IconButton
                                aria-label="open menu"
                                icon={<AiOutlinePlus />}
                                onClick={() =>
                                    append({
                                        item: "",
                                        qty: 0,
                                        unit_price: 0,
                                        amount: 0,
                                        serial_number: "",
                                    })
                                }
                                size={{base:"xs", lg:"md"}}
                                variant='outline'

                            />
                            <IconButton
                                aria-label="open menu"
                                icon={<AiOutlineMinus />}
                                onClick={() => remove(index)}
                                size={{base:"xs", lg:"md"}}
                                variant='outline'


                            />
                            </HStack>
                            </HStack>
                        </FormControl>
                        
                        ))}
                        </FormControl>
                        </Box>

                        </>
                        )}
                        
                    
                        <p>{errors.samplerequest_items?.root?.message}</p>
                


                {kind == "Sample" && (
                <>
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='Request Form'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    3.  Request Form 을 작성하는 이유를 적어주세요.
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Special Condition</FormLabel>
                                <Textarea {...register("description", { required: true })}  />
                        </FormControl>
                </Box>
                </>
                )}

                {kind == "Demo" && (
                <>
        
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='Demo 사용'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    3.  Demo 사용 의 목적을 적어주세요.
                </Highlight>
                </Heading>

                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                    <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                            {/* <Text ml="50px" mb="20px" fontSize="xl">결제방법</Text> */}
                            <Box mb="30px" display="inline-block">
                            <Box display="inline-block" textAlign={{base: "left", lg: "right"}} className="radio"> 
                            <Box className="radio-toolbar">
                            <input id="trial" type="radio" {...register("demo_kind")} value="TRIAL" /> 
                            <FormLabel htmlFor="trial" fontSize={{base: "10px", lg:"14px"}}>TRIAL</FormLabel>
                            </Box>
                            </Box>
                            <Box display="inline-block"  > 
                            <Box className="radio-toolbar">
                            <input id="repair" type="radio" {...register("demo_kind")} value="REPAIR" />
                            <FormLabel htmlFor="repair" fontSize={{base: "10px", lg:"14px"}}>REPAIR</FormLabel>
                            </Box>
                            </Box>
                            </Box>
                    </FormControl>
                </Box>
                
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='Demo 제품'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    4. Demo 제품 의 테스트 여부를 적어주세요 (By TS TEAM).
                </Highlight>
                </Heading>

                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                    <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                            {/* <Text ml="50px" mb="20px" fontSize="xl">결제방법</Text> */}
                            <Box mb="30px" display="inline-block">
                            <Box display="inline-block" textAlign={{base: "left", lg: "right"}} className="radio"> 
                            <Box className="radio-toolbar">
                            <input id="yes" type="radio" {...register("demo_tested")} value="Yes" /> 
                            <FormLabel htmlFor="yes" fontSize={{base: "10px", lg:"14px"}}>Yes</FormLabel>
                            </Box>
                            </Box>
                            <Box display="inline-block"  > 
                            <Box className="radio-toolbar">
                            <input id="no" type="radio" {...register("demo_tested")} value="No" />
                            <FormLabel htmlFor="no" fontSize={{base: "10px", lg:"14px"}}>No</FormLabel>
                            </Box>
                            </Box>
                            </Box>
                    </FormControl>
                </Box>

                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='Demo Request Form'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    5.  Demo Request Form 을 작성하는 이유를 적어주세요.
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Special Condition</FormLabel>
                                <Textarea {...register("description", { required: true })}  />
                        </FormControl>
                </Box>
                </>
                )}
                {/* 
                ***
                6. Sales Agreement Terms & Conditions
                ***
                */}
                
                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">6.</Text><Text display="inline-block" textDecoration="underline">TERMS & CONDITION</Text></Box> */}

                <Box>
                    <VStack spacing={15} as="form" onSubmit={handleSubmit(onSubmit)} mt={5}>

                    <p>{errors.samplerequest_items?.root?.message}</p>

                    {mutation.isError ? (
                        <Text color="red.500">Something went wrong</Text>
                    ) : null}
                    <Button
                    type="submit"
                    isLoading={mutation.isLoading}
                    colorScheme="facebook"
                    size="lg"
                    w={{base: "100%", lg: "200px"}}
                    >
                    Request Form 생성
                    </Button>
                    </VStack>
                </Box>
            </Box>
        </ProtectedPage>
    )
}
