import { Badge, Box, Button, Checkbox, Container, FormControl, FormHelperText, FormLabel, Grid, Heading, HStack, Input, Select, Stack, Textarea, useToast, VStack, Text, Icon, IconButton } from "@chakra-ui/react";
import { getQuoteTNC, IUploadRepairVariables, uploadRepair } from "../../api";
import ProtectedPage from "../../components/ProtectedPage";
import useSalesOnlyPage from "../../components/SalesOnlyPage";
import { IClient, IRepairDetail, IUser } from "../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, Control, useWatch, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { ChangeEvent, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";



export default function UploadRepair() {
    const { control, register, handleSubmit } = useForm<IUploadRepairVariables>();

    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation(uploadRepair, {
    onSuccess: (data: IRepairDetail) => {
        console.log("------------");
        console.log(data);
        console.log("------------");

        toast({
        status: "success",
        title: "Repair Form created",
        position: "bottom-right",
        });
        navigate(`/repairs/${data.id}`);
    },
    });
    useSalesOnlyPage();
    const onSubmit = (data: IUploadRepairVariables) => {
        console.log(data)
        mutation.mutate(data)
    }
    return (
        <ProtectedPage>
            <Box
                pb={40}
                mt={10}
                px={{  
                    base: 10,
                    lg: 40,
                }}
            >
                <Box>
                    <Heading fontSize="2xl" textAlign={"center"}>Create Repair</Heading>
                    <VStack spacing={15} as="form" onSubmit={handleSubmit(onSubmit)} mt={5}>
                    
                    <FormControl>
                        <HStack>
                            <FormControl>
                                    <FormLabel>Clinic Name</FormLabel>
                                    <Input {...register("client.clinic", { required: true })}  type="text" />
                            </FormControl>
                            <FormControl>
                                    <FormLabel>Dr Name</FormLabel>
                                    <Input {...register("client.name", { required: true })} type="text" />
                            </FormControl>
                            
                        </HStack>
                        
                    </FormControl>
                    <FormControl>
                            <FormLabel>Client Code</FormLabel>
                            <Input {...register("client.abn", { required: true })} type="text" />
                            <FormHelperText>Ecount Code</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Sales Date</FormLabel>
                        <Input
                            {...register("sales_date", {required: true})}
                            placeholder="ex) 01/Jan/2022"
                            size="md"
                            />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Received Date from Clinic</FormLabel>
                        <Input
                            {...register("received_date", {required: true})}
                            placeholder="ex) 01/Dec/2022"
                            size="md"
                            />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Manufacturer Name</FormLabel>
                        <Input {...register("manufacturer", { required: true })} type="text" />
                        <FormHelperText>eg. Vatech, Dr. Kim ...</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Product Name</FormLabel>
                        <Input {...register("product_name", { required: true })} type="text" />
                        <FormHelperText>eg. Vatech A9, Dr. Kim Headlight ...</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Serial Number</FormLabel>
                        <Input {...register("serial_number", { required: true })} type="text" />
                    </FormControl>
                    <FormControl>
                            <FormLabel>Composition List</FormLabel>
                            <Textarea {...register("composition_list", { required: true })}  />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Under Warrnaty? </FormLabel>
                        <Select {...register("kind", { required: true })}
                            placeholder="Choose a kind"
                        >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                            <FormLabel>Details of requested work and/or description of problem *</FormLabel>
                            <Textarea {...register("whyrepair", { required: true })}  />
                    </FormControl>
                    
                    {mutation.isError ? (
                        <Text color="red.500">Something went wrong</Text>
                    ) : null}
                    <Button
                    type="submit"
                    isLoading={mutation.isLoading}
                    colorScheme={"red"}
                    size="lg"
                    w="100%"
                    >
                    Upload Request Form
                    </Button>
                    </VStack>
                </Box>
            </Box>
        </ProtectedPage>
    )
}