import { Badge, Button, Td, Tr } from "@chakra-ui/react";
import { Link,  } from "react-router-dom";
import { ISamplerequestItems } from "../../types";
import { getMe } from '../../api';
import { useQuery } from '@tanstack/react-query';

interface ISamplerequestProp {
    id: number;
    sr_id: string;
    kind: string;
    demo_kind: string;
    demo_tested: string;
    submitter: string;
    srdrname: string 
    sr_submission_date: string;
    srclinicname: string;
    srcliniccode: string;
    srbillingemail: string;
    samplerequest_items: ISamplerequestItems[];
    description: string;


    sr_total_price: number;
    sr_gst_price: number;
    sr_final_price: number;

}

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}

export default function Samplerequests({
    id,
    sr_id,
    kind,
    demo_kind,
    demo_tested,
    submitter,
    srdrname,
    sr_submission_date,
    srclinicname,
    srcliniccode,
    srbillingemail,
    samplerequest_items,
    description,
    sr_final_price

    
}: ISamplerequestProp)
 {

    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);

    function srType() {
        if(kind === "Sample") {
            return <Td fontSize="xs"><Badge colorScheme="blue">{kind}</Badge></Td>
        } 
        else if(kind ==="Demo") {
            return <Td fontSize="xs"><Badge colorScheme="red">{kind}</Badge></Td>
        }
        else if(kind ==="Draft Proposal") {
            return <Td fontSize="xs"><Badge colorScheme="green">{kind}</Badge></Td>
        }
        return <Td fontSize="xs">-</Td>
    }

    function truncateText(text?: string, maxLength: number = 20) {
        if (!text || text.length === 0) {
            return "";
        }
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    }
    
    return (  
        <>    
        {submitter === userData?.name && (
            <>
            <Tr>
                <Td><Link to={`/samplerequests/${id}`}><Button size="xs" colorScheme="gray">{sr_id}</Button></Link></Td>

                {userData?.role == "Boss" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.role == "Manager" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Charlie Hong" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name ==="Sean Hyun" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Skylar Jang" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Prisca Yong" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Ellena Sim" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Ellena Sim" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                <Td fontSize="xs">{sr_submission_date}</Td>
                { srType() }
                <Td fontSize="xs">{submitter}</Td>
                <Td fontSize="xs">{srdrname}</Td>
                <Td fontSize="xs">{truncateText(srclinicname)}</Td>
                <Td fontSize="xs">{truncateText(description)}</Td>
            </Tr>
            </>
        )} 
        {submitter !== userData?.name && (
            <>
            <Tr>
                <Td><Link to={`/samplerequests/${id}`}><Button size="xs" colorScheme="gray">{sr_id}</Button></Link></Td>
                
                {userData?.role == "Boss" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.role == "Manager" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Charlie Hong" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name ==="Sean Hyun" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Skylar Jang" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Prisca Yong" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name === "Steve Kim" && (
                    <Td><Link to={`/samplerequests/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                <Td fontSize="xs">{sr_submission_date}</Td>
                { srType() }
                <Td fontSize="xs">{submitter}</Td>
                <Td fontSize="xs">{srdrname}</Td>
                <Td fontSize="xs">{truncateText(srclinicname)}</Td>
                <Td fontSize="xs">{truncateText(description)}</Td>

            </Tr>
            </>
        )}    
        </>
    )
}