import { Badge, Button, Td, Tr } from "@chakra-ui/react";
import { VscFilePdf } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
interface IRepairProp {
    id: number;
    repair_id: string;
    submitter: string;
    dentist: string;
    clinic: string;
    kind: string;
    product_name: string;
    whyrepair: string;
    submission_date: string;

}
export default function Repairs({
    id,
    repair_id,
    submitter,
    dentist,
    clinic,
    kind,
    product_name,
    whyrepair,
    submission_date,
}: IRepairProp) {
    const navigate = useNavigate();
    function repairType() {
        if(kind === "Yes") {
            return <Td fontSize="xs"><Badge colorScheme="blue">{kind}</Badge></Td>
        } 
        else if(kind ==="No") {
            return <Td fontSize="xs"><Badge colorScheme="red">{kind}</Badge></Td>
        }
        return <Td fontSize="xs">-</Td>
    }
    return (
        <>            
            <Tr>
                <Td><Link to={`/repairs/${id}`}><Button size="xs" colorScheme="gray">{repair_id}</Button></Link></Td>
                <Td fontSize="xs">{submitter}</Td>
                <Td fontSize="xs">{dentist}</Td>
                <Td fontSize="xs">{clinic}</Td>
                <Td fontSize="xs">{product_name}</Td>
                { repairType() }
                <Td fontSize="xs">{whyrepair}</Td>
            </Tr>
        </>

    )
}