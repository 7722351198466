import { Box } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet } from "react-router-dom";
import { getMe } from "../api";
import useUser from "../lib/useUser";
import Home from "../routes/Home";
import Login from "../routes/Login";
import { IUser } from "../types";
import Header from "./Header/Index";

export default function Root() {
	const { userLoading, isLoggedIn, user } = useUser();

    return (
		<>
		{
			!userLoading ? (
			!isLoggedIn ? (
				<Login />
			) : (
				<Box>
				<Header></Header>
					<Box
								float='right'
								minHeight='100vh'
								overflow='auto'
								position='relative'
								w={{ base: '100%', lg: 'calc( 100% - 240px )' }}
								p={{ base: 0, lg: 5 }}
								maxWidth={{ base: '100%', lg: 'calc( 100% - 240px )' }}
								transition='all 0.01s cubic-bezier(0.685, 0.0473, 0.346, 1)'
								transitionDuration='.02s, .02s, .035s'
								transitionProperty='top, bottom, width'
								transitionTimingFunction='linear, linear, ease'>
						<Outlet></Outlet>
						<ReactQueryDevtools />
					</Box>
				</Box>
			)
		) : null }
		</>
    );
}