import Cookie from "js-cookie";
import {QueryFunctionContext} from "@tanstack/react-query"
import axios from "axios";
import { IClient, IQuotationItems, IQuoteTNC, ISATNC, IUser, ISalesAgreementItems, ISalesAgreementPays, ISamplerequestItems, ILeaveformDates } from "./types";

const instance = axios.create({
    baseURL: process.env.NODE_ENV=== "development" ? "http://127.0.0.1:8000/api/v1/" : "https://backend.mmximp.com.au/api/v1/",
    withCredentials: true,
});
// export const getQuotations =  ({pageParam = 1}) => {
//     const response = await instance.get(`quotations/?page=${pageParam}`)
//     return instance.get(`quotations?page=${pageParam}`).then((response) => response.data);
// };

export const getSamplerequests = () => 
    
    instance.get(`samplerequests/`).then((response) => response.data);

export const getSamplerequest = ({ queryKey }: QueryFunctionContext) => {
    const [_, samplerequestPk] = queryKey;
    return instance.get(`samplerequests/${samplerequestPk}`).then((response) => response.data);
};
// export const getSamplerequestItems = ({ queryKey }: QueryFunctionContext) => {
//     const [_, samplerequestitemsPk] = queryKey;
//     return instance.get(`samplerequest_items/${samplerequestitemsPk}`).then((response) => response.data);
// };
export const getProductDatas = () => 
    
    instance.get(`productdata/`).then((response) => response.data);

export const getQuotations = () => 
    
    instance.get(`quotations/`).then((response) => response.data);

export const getQuotation = ({ queryKey }: QueryFunctionContext) => {
    const [_, quotationPk] = queryKey;
    return instance.get(`quotations/${quotationPk}`).then((response) => response.data);
};

export const getQuotations_draft = () => 
    instance.get(`quotations_draft/`).then((response) => response.data);


export const getQuotation_draft = ({ queryKey }: QueryFunctionContext) => {
    const [_, quotationPk] = queryKey;
    return instance.get(`quotations_draft/${quotationPk}`).then((response) => response.data);
};
export const getBoards = () => 
    instance.get(`boards/`).then((response) => response.data);

export const getBoard = ({ queryKey }: QueryFunctionContext) => {
    const [_, boardPk] = queryKey;
    return instance.get(`boards/${boardPk}`).then((response) => response.data);
};
export const getRepairs = () => 
    instance.get(`repairs/`).then((response) => response.data);

export const getRepair = ({ queryKey }: QueryFunctionContext) => {
    const [_, repairPk] = queryKey;
    return instance.get(`repairs/${repairPk}`).then((response) => response.data);
};
// export const getSalesAgreements = async (pageParam = 1) => {
//     const response = await instance.get(`salesagreements/?page=${pageParam}`)
//     return response.data
// };
export const getSalesAgreements = () => 
    instance.get(`salesagreements/`).then((response) => response.data);

export const getSalesAgreement = ({ queryKey }: QueryFunctionContext) => {
    const [_, salesagreementPk] = queryKey;
    return instance.get(`salesagreements/${salesagreementPk}`).then((response) => response.data);
};

export const getLeaveforms = () => 
    instance.get(`leaveforms/`).then((response) => response.data);

export const getLeaveform = ({ queryKey }: QueryFunctionContext) => {
    const [_, leaveformPk] = queryKey;
    return instance.get(`leaveforms/${leaveformPk}`).then((response) => response.data);
};
export const getMe = () => 
    instance.get(`users/me`).then((response) => response.data);
export const getClient = () => 
    instance.get(`clients/`).then((response) => response.data);
export const logOut = () =>
    instance
    .post(`users/log-out`, null, {
        headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",               
        },
    })
    .then((response) => response.data);

export interface IUsernameLoginVariables {
    username: string;
    password: string;
}
export interface IUsernameLoginSuccess {
    ok: string;
}
export interface IUsernameLoginError {
    error: string;
}
export const usernameLogIn = ({
    username,
    password,
}: IUsernameLoginVariables) =>
    instance
        .post(
            `/users/log-in`,
            { username, password },
            {
                headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
            }
        )
    .then((response) => response.data);

export const getQuoteTNC = () =>
    instance.get(`quotations/quotetnc`).then((response) => response.data);
export const getQuoteTNC_draft = () =>
    instance.get(`quotations_draft/quotetnc`).then((response) => response.data);

export interface IUploadQuotationVariables {
    id: number;
    quotation_id: string;
    title: string;
    date: Date;
    expired_date: Date;
    user: IUser;
    client: IClient;
    kind: string;
    quotation_items: IQuotationItems[];
    total_price: number;
    gst_total: number;
    final_price: number;
    special_condition: string;
    quotetncs: IQuoteTNC[];
    billingcheck: boolean;
}
export interface IUploadQuotationVariables_Draft {
    id: number;
    quotation_id: string;
    title: string;
    date: Date;
    expired_date: Date;
    user: IUser;
    client: IClient;
    kind: string;
    quotation_items: IQuotationItems[];
    total_price: number;
    gst_total: number;
    final_price: number;
    special_condition: string;
    quotetncs: IQuoteTNC[];
    billingcheck: boolean;
}

export const updateQuotation = (variables: IUploadQuotationVariables) =>
instance
    .put(`quotations/`, variables, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then((response) => response.data);
    
export const uploadQuotation = (variables: IUploadQuotationVariables) =>
instance
    .post(`quotations/`, variables, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then((response) => response.data);

export const uploadQuotation_draft = (variables: IUploadQuotationVariables_Draft) =>
    instance
        .post(`quotations_draft/`, variables, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
        })
        .then((response) => response.data);

export const getSATNC = () =>
instance.get(`salesagreements/satnc`).then((response) => response.data);

export interface IUploadSalesAgreementVariables {
    id: number;
    salesagreement_id: string;
    date: Date;
    user: IUser;
    client: IClient;
    kind: string;
    salesagreement_items: ISalesAgreementItems[];
    salesagreement_pays: ISalesAgreementPays[];
    payment_method: string;
    payment_term: string;
    payment_date: string;
    payday: string;
    paymonth: string;
    payyear: string;
    credit_type: string;
    credit_no: string;
    credit_exmonth: string;
    credit_exyear: string;
    credit_cvv: string;
    loan_company: string;
    loan_rep: string;
    loan_mobile: string;
    loan_email: string;
    total_price: number;
    gst_total: number;
    final_price: number;
    special_condition: string;
    satncs: ISATNC[];
}
export const uploadSalesAgreement = (variables: IUploadSalesAgreementVariables) =>
instance
    .post(`salesagreements/`, variables, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then((response) => response.data);


export const updateSalesAgreement = (id: number, variables: IUploadSalesAgreementVariables) => {
    
        return instance.put(`salesagreements/${id}/edit/`, variables, {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                },
            }).then((response) => response.data);
        };


        
export interface IUploadBoardVariables {
    id: number;
    submission_date: string;
    board_id: string;
    title: string;
    description: string;
    submitter: IUser;
}
export const uploadBoard = (variables: IUploadBoardVariables) =>
    instance
    .post(`boards/`, variables, {
        headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    })
    .then((response) => response.data);

export interface IUploadRepairVariables {
    id: number;
    repair_id: string;
    submission_date: Date;
    sales_date: Date;
    received_date: Date;
    manufacturer: string;
    product_name: string;
    serial_number: string;
    composition_list: string;
    under_warranty: string;
    whyrepair: string;
    submitter: IUser;
    client: IClient;
    kind: string;
}
    
export const uploadRepair = (variables: IUploadRepairVariables) =>
    instance
    .post(`repairs/`, variables, {
        headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    })
    .then((response) => response.data);

    
export interface IUploadSamplerequestVariables {
    id: number;
    sr_id: string;
    srdrname: string
    submitter: IUser;
    kind: string;
    demo_kind: string;
    demo_tested: string;
    sr_submission_date: string;
    srclinicname: string;
    srcliniccode: string;
    srbillingemail: string;
    description: string;
    samplerequest_items: ISamplerequestItems[];
    total_price: number;
    gst_total: number;
    final_price: number;

}
    
export const uploadSamplerequest = (variables: IUploadSamplerequestVariables) =>
instance
    .post(`samplerequests/`, variables, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then((response) => response.data);

export interface IUploadLeaveformVariables {
    id: number;
    leaveform_id: string;
    submission_date: string;
    reasonForRequest: string;
    remark: string;
    submitter: IUser;
    kind: string;
    leaveform_dates: ILeaveformDates[];
    leaveTotalDays: string;
    comment: string;
}
export const uploadLeaveform = (variables: IUploadLeaveformVariables) =>
instance
    .post(`leaveforms/`, variables, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then((response) => response.data);


export const updateSamplerequest = (id: number, variables: IUploadSamplerequestVariables) => {
    
    return instance.put(`samplerequests/${id}/edit/`, variables, {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }).then((response) => response.data);
    };



export const updateLeaveform = (id: number, variables: IUploadLeaveformVariables) => {
    
    return instance.put(`leaveforms/${id}/edit/`, variables, {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }).then((response) => response.data);
    };
