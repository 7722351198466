import { Badge, Button, Td, Tr } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../api";
import { ISalesAgreementItems, ISATNC,ISalesAgreementPays } from "../../types";


interface ISalesAgreementProp {
    id: number;
    salesagreement_id: string;
    name: string;
    date: string;
    dentist: string;
    clinic: string;
    address: string;
    mobile: string;
    email: string;
    abn: string;
    salesagreement_items: ISalesAgreementItems[];
    salesagreement_pays: ISalesAgreementPays[];
    satncs: ISATNC[];
    kind: string;
    payment_method: string;
    payment_term: string;

    sa_total_price: number;
    sa_gst_price: number;
    sa_final_price: number;

}

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}
export default function SalesAgreements({
    id,
    salesagreement_id,
    date,
    name,
    dentist,
    clinic,
    kind,
    sa_final_price,

    
}: ISalesAgreementProp) {
    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
    function salesagreementType() {
        if(kind === "Implant") {
            return <Td fontSize="xs"><Badge colorScheme="blue">{kind}</Badge></Td>
        } 
        else if(kind ==="Equipment") {
            return <Td fontSize="xs"><Badge colorScheme="red">{kind}</Badge></Td>
        }
        else if(kind ==="Seminar") {
            return <Td fontSize="xs"><Badge colorScheme="pink">{kind}</Badge></Td>
        }
        return <Td fontSize="xs">-</Td>
    }


    function truncateText(text?: string, maxLength: number = 20) {
        if (!text || text.length === 0) {
            return "";
        }
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    }

    return (        
        <>
        {name === userData?.name && (

        <Tr>
                <Td><Link to={`/salesagreements/${id}`}><Button size="xs" colorScheme="gray">{salesagreement_id}</Button></Link></Td>
                {userData?.role == "Boss" && (
                <Td><Link to={`/salesagreements/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                {userData?.name == "Charlie Hong" && (
                <Td><Link to={`/salesagreements/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                <Td fontSize="xs">{date}</Td>
                <Td fontSize="xs">{name}</Td>
                <Td fontSize="xs">{truncateText(dentist)}</Td>
                <Td fontSize="xs">{truncateText(clinic)}</Td>
                <Td fontSize="xs">{(Math.round(sa_final_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Td>

            </Tr>
        )}
        {name !== userData?.name && (
            <Tr backgroundColor="#fafafa">
                <Td><Link to={`/salesagreements/${id}`}><Button size="xs" colorScheme="gray">{salesagreement_id}</Button></Link></Td>
                {userData?.role == "Boss" && (
                <Td><Link to={`/salesagreements/${id}/edit`}><Button size="xs" colorScheme="gray">수정</Button></Link></Td>
                )}
                <Td fontSize="xs">{date}</Td>
                <Td fontSize="xs">{name}</Td>
                <Td fontSize="xs">{truncateText(dentist)}</Td>
                <Td fontSize="xs">{truncateText(clinic)}</Td>
                <Td fontSize="xs">{(Math.round(sa_final_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Td>

            </Tr>
        )}
        </>
    )
}