import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingTop: 30,
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: "20px",
    fontSize: 12,
    fontFamiy: 'Noto Sans',

  },
  container_inside: {
    display: 'flex',
    fontSize: 10,
    paddingBottom: 9,
  },
  b: {
    flexDirection: 'row',
    fontSize: 10,
    color: '#000',
    marginTop: "10px"

  },
  b_2: {
    flexDirection: 'row',
    marginTop: '25px',
  },
  b_inside: {
    flexDirection: 'row',
  },
  
})

interface HeaderProps {
    kind: string;
    demo_kind: string;
    sr_id: string;
    submitter: string;
    srdrname: string;
    sr_submission_date: string;
    srclinicname: string;
    srcliniccode: string;
    srbillingemail: string;
    children?: React.ReactNode;
  }
function Middle1(props: HeaderProps) {
return (
  
    <View style={styles.container}>
        <View style={styles.b}>
            <View style={{flex: "1",}}>
                <View style={styles.container_inside}>

                    <View style={styles.b}>
                        <View style={{flex: "2",}}>
                            <Text style={{fontFamily: "Noto Sans"}}>Dr's Name: </Text>
                            <Text style={{fontFamily: "Noto Sans"}}>Clinic Name: </Text>
                            <Text style={{fontFamily: "Noto Sans"}}>Clinic Code: </Text>
                        </View>
                        <View style={{marginLeft: '1px',flex: "9",}}>
                            <Text style={{fontFamily: "Noto Sans"}}>{props.srdrname}</Text>
                            <Text style={{fontFamily: "Noto Sans"}}>{props.srclinicname}</Text>
                            <Text style={{fontFamily: "Noto Sans"}}>{props.srcliniccode}</Text>
                        </View>
                    </View>

                </View>
            </View>
            
            <View style={{marginLeft: "15px"}}>
                <View style={styles.container_inside}>
                    <View style={styles.b_2}>
                        <View style={{}}>
                            <Text style={{fontFamily: "Noto Sans"}}>Request ID: </Text>
                            <Text style={{fontFamily: "Noto Sans"}}>Sales Rep: </Text>
                            <Text style={{fontFamily: "Noto Sans"}}>Date: </Text>
                        </View>
                        <View style={{marginLeft: '1px'}}>
                            <Text style={{marginLeft: '10px', fontFamily: "Noto Sans"}}>{props.sr_id}</Text>
                            <Text style={{marginLeft: '10px', fontFamily: "Noto Sans"}}>{props.submitter}</Text>
                            <Text style={{marginLeft: '10px', fontFamily: "Noto Sans"}}>{props.sr_submission_date}</Text>
                        </View>
                        
                    </View>
                </View>


                {/* <View style={{ alignItems: 'flex-start' }}>
                <Text style={{ color: '#000', fontSize: '11px', marginLeft: '10px', marginBottom: '5px'}}>MINIMAX IMPLANT PTY LTD</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Quotation No: {props.quotation_id}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Sales Rep: {props.user_name}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Date: {props.date}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Expire Date: {props.expire_date}</Text>
                </View>
                <View style={{ alignItems: 'flex-end' }}>
                <Text style={{ color: '#000', fontSize: '11px', marginLeft: '10px', marginBottom: '5px'}}>MINIMAX IMPLANT PTY LTD</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Quotation No: {props.quotation_id}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Sales Rep: {props.user_name}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Date: {props.date}</Text>
                <Text style={{ color: '#000', fontSize: '8px', marginLeft: '10px'}}>Expire Date: {props.expire_date}</Text>
                </View> */}
            </View>
            
        </View>
    </View>
    
)
}

export default Middle1
