import { Badge, Button, Td, Tr } from "@chakra-ui/react";
import { Link,  } from "react-router-dom";
import { IQuotationItems_Draft, IQuoteTNC_Draft } from "../../types";
import { getMe } from '../../api';
import { useQuery } from '@tanstack/react-query';

interface IQuotationProp_Draft {
    id: number;
    quotation_id: string;
    name: string;
    date: string;
    expire_date: string;
    dentist: string;
    clinic: string;
    address: string;
    mobile: string;
    email: string;
    abn: string;
    kind: string;
    title: string;
    quotation_items: IQuotationItems_Draft[];
    quotetncs: IQuoteTNC_Draft[];

    quote_total_price: number;
    quote_gst_price: number;
    quote_final_price: number;

}

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}

export default function Quotations_Draft({
    id,
    quotation_id,
    title,
    date,
    expire_date,
    name,
    dentist,
    clinic,
    kind,
    quote_final_price,

    
}: IQuotationProp_Draft)
 {

    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);

    function quotationType() {
        if(kind === "Implant") {
            return <Td fontSize="xs"><Badge colorScheme="blue">{kind}</Badge></Td>
        } 
        else if(kind ==="Equipment") {
            return <Td fontSize="xs"><Badge colorScheme="red">{kind}</Badge></Td>
        }
        else if(kind ==="Seminar") {
            return <Td fontSize="xs"><Badge colorScheme="pink">{kind}</Badge></Td>
        }
        return <Td fontSize="xs">-</Td>
    }
    return (  
        <>      
        {name === userData?.name && (
            <>
            <Tr>
                <Td><Link to={`/quotations_draft/${id}`}><Button size="xs" colorScheme="gray">{quotation_id}</Button></Link></Td>
                <Td fontSize="xs">{date}</Td>
                <Td fontSize="xs">{name}</Td>
                <Td fontSize="xs">{dentist}</Td>
                <Td fontSize="xs">{clinic}</Td>
                <Td fontSize="xs">{(Math.round(quote_final_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Td>
                { quotationType() }
                
                <Td><Link to={`/quotations_d/${id}/upload`}><Button size="xs" colorScheme="gray">견적서</Button></Link></Td>
            </Tr>
            </>
        )} 
        {name !== userData?.name && (
            <>
            <Tr>
                <Td><Link to={`/quotations_draft/${id}`}><Button size="xs" colorScheme="gray">{quotation_id}</Button></Link></Td>
                <Td fontSize="xs">{date}</Td>
                <Td fontSize="xs">{name}</Td>
                <Td fontSize="xs">{dentist}</Td>
                <Td fontSize="xs">{clinic}</Td>
                <Td fontSize="xs">{(Math.round(quote_final_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Td>
                { quotationType() }
                
                <Td><Link to={`/quotations_d/${id}/upload`}><Button size="xs" colorScheme="gray">견적서</Button></Link></Td>
            </Tr>
            </>
        )}    
        </>
    )
}