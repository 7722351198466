import { StyleSheet, Text, View, Font } from '@react-pdf/renderer'

import Opensans1 from "../../font/open-sans-700.ttf"
import Opensans2 from "../../font/open-sans-regular.ttf"

Font.register({
    family: 'Open Sans',
    fonts: [
      {
        src: Opensans1,
      },
      {
        src: Opensans2,
        FontWeight: "400"
      },
      
    ],
  });
const styles = StyleSheet.create({
  container: {
    borderTop: '1px solid #101031',
    backgroundColor: '#101031',
    color: "#fff",
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    marginTop: 32,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
    fontFamily: 'Open Sans',
  },
  left: {
    flex: 1,
    marginTop: "10px"
    
  },
  right: {
    fontStyle: 'italic',
    
  },
  hrAddress: {
    width: 125,
    border: '0.5px solid #fff',
    marginTop: 2,
    marginBottom: 2,
  },
  hrPhone: {
    width: 50,
    border: '0.5px solid #fff',
    marginTop: 2,
    marginBottom: 2,
  },
  hrEmail: {
    width: 105,
    border: '0.5px solid #fff',
    marginTop: 2,
    marginBottom: 2,
  },
  footerAddress: {
    fontSize: 9,
    fontWeight: 'bold',
    marginRight: 20,
  },
  footerPhone: {
    fontSize: 9,
    fontWeight: 'bold',
    marginRight: 20,
  },
  footerEmail: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  footerTitle: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  footerContent: {
    fontSize: 8,
  }
})

function Footer() {
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Text style={{fontFamily: "Open Sans", fontWeight: 700}}>MINIMAX IMPLANT PTY LTD</Text>
        <Text style={{fontSize: "7px", marginTop: "2px", marginBottom: "2px"}}>ABN: 60 154 715 705</Text>
      </View>
      <View style={styles.footerAddress}>
        <Text style={styles.footerTitle}>Address</Text>
        <Text style={styles.hrAddress}></Text>
        <Text style={styles.footerContent}>Suite 4.05, Level 4, 6 Eden Park Drive,</Text>
        <Text style={styles.footerContent}>Macquarie Park NSW 2113</Text>
      </View>
      <View style={styles.footerPhone}>
        <Text style={styles.footerTitle}>Phone</Text>
        <Text style={styles.hrPhone}></Text>
        <Text style={styles.footerContent}>02 8084 2900</Text>
      </View>
      <View style={styles.footerEmail}>
        <Text style={styles.footerTitle}>Email</Text>
        <Text style={styles.hrEmail}></Text>
        <Text style={styles.footerContent}>info@minimaximplant.com.au</Text>
      </View>
    </View>
  )
}

export default Footer
