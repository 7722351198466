import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {FaUserNinja, FaLock} from "react-icons/fa"
import {Button, useBreakpointValue, Flex, FormControl, FormLabel, Heading, HStack, Image, Input, InputGroup, InputLeftElement, InputRightElement, Link, Stack, Switch, Text, VStack, Box, useToast} from "@chakra-ui/react";
import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IUsernameLoginError,
  IUsernameLoginSuccess,
  IUsernameLoginVariables,
  usernameLogIn,
} from "../api";
import { useNavigate } from "react-router-dom";
import image from "../components/img/mmximp.jpg"

const Login = () => {
    interface IForm {
        username: string;
        password: string;
    }
    const [showPassword, setShowPassword] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IForm>();
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const mutation = useMutation(usernameLogIn, {
        onMutate: () => {
        console.log("mutation starting");
        },
        onSuccess: (data) => {
        toast({
            title: "welcome back!",
            status: "success",
        });
        navigate(`/product`);

        queryClient.refetchQueries(["me"]);
        },
        onError: (error) => {
          console.log("mutation has an error");
        },
      });
      const onSubmit = ({ username, password }: IForm) => {
        mutation.mutate({ username, password });
    };
    return (
        <HStack w="full" h="100vh">
            <Flex w="full" h="full"  display={{base:"none", lg:"flex"}}>
                <Image
                    objectFit="cover"
                    w="full"
                    h="full"
                    src={image}
                    borderBottomRightRadius={200}
                />
            </Flex>
            <Flex w="full" h="full" alignItems="center" justifyContent="center">
                <Stack w="full" maxW="md" spacing={5} p={4}>
                    <VStack alignItems="left" mb={{base:"none", lg:"2"}}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} color="black.700">
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                            content: "''",
                            width: 'full',
                            height: useBreakpointValue({ base: '20%', lg: '30%' }),
                            position: 'absolute',
                            bottom: 1,
                            left: 0,
                            bg: 'blue.400',
                            zIndex: -1,
                            }}>
                            Sign In
                        </Text>
                        </Heading>
                        
                        <Text fontSize="md" color="blue.400" >
                            Enter your email and password to sign in!
                        </Text>
                    </VStack>
                    <FormControl as="form" onSubmit={handleSubmit(onSubmit)}>
                        <FormControl id="user" mt={"0"} isRequired> 
                            <FormLabel>Username</FormLabel>
                            <InputGroup>
                            <InputLeftElement children={<Box color="gray.400"><FaUserNinja/></Box>} />
                            <Input
                                isInvalid={Boolean(errors.username?.message)}
                                {...register("username", {
                                    required: "Please write a username",
                                })}
                                variant={"filled"}
                                placeholder="Username"
                            />
                            </InputGroup>
                        </FormControl>
                        <FormControl id="password" isRequired>
                            <FormLabel mt={"5"}>Password</FormLabel>
                            <InputGroup>
                                <InputLeftElement children={<Box color="gray.400"><FaLock/></Box>} />
                                <Input
                                    isInvalid={Boolean(errors.password?.message)}
                                    {...register("password", {
                                        required: "Please write a password",
                                    })}
                                    type={showPassword ? 'text' : 'password'}
                                    variant={"filled"}
                                    placeholder="Password"
                                />
                                <InputRightElement h={'full'}>
                                <Button
                                    variant={'ghost'}
                                    color="gray.400"
                                    onClick={() =>
                                    setShowPassword((showPassword) => !showPassword)
                                    }>
                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Stack
                            mt="8"
                            spacing={4}
                            direction="row"
                            align="start"
                            justify="space-between"
                        >
                            <HStack>
                            <FormControl display='flex' alignItems='center'>
                            <Switch id='email-alerts' />
                            <FormLabel htmlFor='email-alerts' ml='5' mb='0'>
                                Remember Me?
                            </FormLabel>
                            </FormControl>
                            </HStack>
                            <Link color="blue.400">Forgot password?</Link>
                        </Stack>
                        <Button isLoading={mutation.isLoading} type="submit" colorScheme="blue" w="full" mt="7">Sign in</Button>
                    </FormControl>
                    <Stack w="full" h="full" alignItems="center" justifyContent="center" fontSize="sm" color="#AAAAAA;"mt="100px">
                        <Text mt={10}>© 2022 Minimax Implant. All Rights Reserved. Made with love by Minimax Implant Team</Text>
                    </Stack>
                </Stack>
                
            </Flex>
            
        </HStack>
    );
}

export default Login;