import {  Box, Button, Checkbox,  FormControl, FormHelperText, FormLabel, Grid, Heading, HStack, Input, Select, Textarea, useToast, VStack, Text,  IconButton, Highlight, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, Stack,useColorModeValue, InputGroup, InputLeftElement, Flex } from "@chakra-ui/react";
import { getProductDatas,  getQuoteTNC_draft, IUploadQuotationVariables_Draft, uploadQuotation_draft } from "../../api";
import ProtectedPage from "../../components/ProtectedPage";
import useSalesOnlyPage from "../../components/SalesOnlyPage";

import ProductDatas from '../../components/ProductData/ProductDatas';
import SearchProduct from '../../components/ProductData/SearchProduct';

import { IProductDataList, IQuotationDetail_Draft,   IQuotationItems_Draft,  IQuoteTNC_Draft } from "../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, Control, useWatch, useFieldArray, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useEffect, useState } from "react";
import React from "react";
import { FaDivide } from "react-icons/fa6";
import { SearchIcon } from "@chakra-ui/icons";


function getTotalPrice(payload: IUploadQuotationVariables_Draft['quotation_items']) {
    let total:any = 0;

    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))
        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100 / 11 * 10).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return total;
}

function getGst(payload: IUploadQuotationVariables_Draft['quotation_items']) {
    let total:any = 0;

    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))
        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100 / 11).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    return total;

}       

function getFinalTotal(payload: IUploadQuotationVariables_Draft['quotation_items']) {
    let total:any = 0;
    
    for (const item of payload) {
        item.amount= ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*(item.unit_price + (item.unit_price/10)))

        total = total + (Number.isNaN(item.amount) ? 0 : item.amount); 
    }
    total = (Math.round(total * 100) / 100).toFixed(2);
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return total;
}
function getAmount(payload: IUploadQuotationVariables_Draft['quotation_items']) {
    let total:any = 0;
    // for (const item of payload) {
    //     item.amount = ((Number.isNaN(item.qty)) || (Number.isNaN(item.unit_price)) ? 0 : item.qty*item.unit_price)
    //     total = item.amount
    // }
    // total = parseInt((Math.round(total * 100) / 100).toFixed(2));
    // return total

    let amount:any= 0;

    payload.forEach((data, index) => {

        amount = data.amount
        data.amount= ((Number.isNaN(data.qty)) || (Number.isNaN(data.unit_price)) ? 0 : data.qty*(data.unit_price + (data.unit_price/10)))
        amount = parseFloat(`data.${index}.amount`)
        return amount;
    })
    
}
function Amount({control}: {control: Control<IUploadQuotationVariables_Draft>}) {
    const values = useWatch({
        control,
        name: `quotation_items`,
    });
    // let amount:any= 0;
    // values.forEach((data, index) => {
    //     data.amount= data.unit_price * data.qty
    //     amount = parseInt(`data.${index}.amount`)
    //     return <>{data.amount}</>
    // })
    return <>{getAmount(values)}</>
}
function TotalPrice({control}: {control: Control<IUploadQuotationVariables_Draft>}) {
    const values = useWatch({
        control,
        name: "quotation_items",
    });

    return <p>{getTotalPrice(values)}</p>
}
function Gst({control}: {control: Control<IUploadQuotationVariables_Draft>}) {
    const values = useWatch({
        control,
        name: "quotation_items",
    });

    return <p>{getGst(values)}</p>
}
function FinalPrice({control}: {control: Control<IUploadQuotationVariables_Draft>}) {
    const values = useWatch({
        control,
        name: "quotation_items",
    });
    return <p>{getFinalTotal(values)}</p>
}


export default function UploadQuotation_Draft() {
   
 


    const { control, register, handleSubmit, formState:{errors}, watch, setValue, getValues } = useForm<IUploadQuotationVariables_Draft>({
        defaultValues: {
            quotation_items: [{
            }]
        }
    });


    function ConditionalInput({ control, index}:{control: Control<IUploadQuotationVariables_Draft>, index: any}) {
        const value = useWatch({
            name: `quotation_items`,
            control
        });
        const qty = useWatch({
            name: `quotation_items.${index}.qty`,
            control
        });
        const unitPrice = useWatch({
            name: `quotation_items.${index}.unit_price`,
            control
        });
        useEffect(() => {
            if (!isNaN(qty) && !isNaN(unitPrice)) {
                const amount = (qty * (unitPrice + (unitPrice / 10)));
                setValue(`quotation_items.${index}.amount`, amount);
            } else {
                setValue(`quotation_items.${index}.amount`, 0);
            }
        }, [qty, unitPrice, index, setValue]);
        return (
            <Controller
                control={control}
                name={`quotation_items.${index}.amount`}
                render={() => value?.[index]?.qty && value?.[index]?.unit_price !== 0 ? 
                <><Input fontSize={{base: "10px", lg:"12px"}}textAlign="center" value={getValues(`quotation_items.${index}.amount`)} {...register(`quotation_items.${index}.amount` as const, { required: true, valueAsNumber: true, })} type="number" disabled/></>
                : 
                <></>
                }
            />
        );
    };
    const watchSameAddress = watch("billingcheck", false); // you can supply default value as second argument
    const { data: quotetncs } = useQuery<IQuoteTNC_Draft[]>(["quotetncs"], getQuoteTNC_draft);
    const { fields, append, remove } = useFieldArray({
        name: "quotation_items", // unique name for your Field Array
        control,// control props comes from useForm (optional: if you are using FormContext)
        rules: {
            required: "Please append at least 1 item",
        }
    });
    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation(uploadQuotation_draft, {
        onError: (error) => {
            console.log(error);
        },
        onSuccess: (data: IQuotationDetail_Draft) => {
            toast({
            status: "success",
            title: "Quotation Created",
            position: "bottom-right",
            });
            navigate(`/quotations_draft/${data.id}`);
        },
    });
    useSalesOnlyPage();
    const onSubmit = (data: IUploadQuotationVariables_Draft) => {
        mutation.mutate(data);
    }

    
    return (
        <ProtectedPage>
            <Box
            padding={20}
            backgroundColor='#0765A3'
            color="#fff"
            pb="16">
                <Box textAlign="center" fontSize="20">CREATE QUOTATION DRAFT</Box>
                
            </Box>
            
            <Box display="flex" justifyContent="space-between" mb="20" mt="20px">
                    <Box><Link to={`/quotations_draft/`}><Button colorScheme='facebook' variant='outline'size={{base: "xs", lg: "lg"}}>&#x2190; 견적서 Draft 리스트</Button></Link></Box>
                    <Box><SearchProduct></SearchProduct></Box>
                    
            </Box>

            <Box
                pb={40}
                mt={10}
                px={{  
                    base: "20px",
                    lg: "10vw",
                }}
            >
                <Box mb={{base: "50px", lg: "100px"}}>
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall'>
                <Highlight
                    query={['제목','카테고리']}
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    0.  견적서 제목 & 카테고리를 입력해주세요
                </Highlight>
                </Heading>
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Quotation Title:</FormLabel>
                            <Input w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("title", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Quotation Title" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <HStack>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "20px"}} fontSize={{base: "10px", lg:"14px"}}>Quotation Type:</FormLabel>
                            <Select {...register("kind", { required: true })}
                                fontSize="15px"
                                placeholder="Choose a kind"
                                w={{base: "150px", lg: "200px"}}
                                variant="flushed"
                                textAlign="center"
                            >
                                <option value="Implant">Implant</option>
                                <option value="Equipment">Equipment</option>
                                <option value="Seminar">Seminar</option>
                            </Select>
                            </HStack>
                        </FormControl>
                    </FormControl>
                </FormControl>
                </Box>

                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall'>
                <Highlight
                    query='고객에 대한 정보'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    1.  견적서를 작성 할 고객에 대한 정보를 입력해주세요
                </Highlight>
                </Heading>
                <FormControl>
                    <FormControl pt="30px" pb="0px">
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Dentist Name:</FormLabel>
                            <Input w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.name", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Dentist Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Name:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.clinic", { required: true })} type="text" pl={{base: "10px", lg:"20px"}} placeholder="Clinic Name" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Address:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.address", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client Address" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Mobile:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.mobile", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client Mobile" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic Email:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.email", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client Email" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                            </FormControl>
                        <FormControl>
                            <FormLabel w={{base: "100%", lg: "150px"}} display="inline-block" textAlign={{base: "left", lg: "right"}} mr="30px" mt={{base: "10px", lg: "0px"}} fontSize={{base: "10px", lg:"14px"}}>Clinic ABN:</FormLabel>
                            <Input  w={{base: "100%", lg: "calc(90% - 150px)"}} variant='flushed' {...register("client.abn", { required: true })} type="text"  pl={{base: "10px", lg:"20px"}} placeholder="Client ABN" _placeholder={{ opacity: 1, color: 'gray.400', size: "8px"}}/>
                        </FormControl>
                    </FormControl>
                </FormControl>
                </Box>
                {/* 
                ***
                4. Sales Agreement Items
                ***
                */}

<Box mb={{base: "50px", lg: "100px"}}>
            

            <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
            <Highlight
                query='계약제품'
                styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
            >
                2.  견적서에 들어가는 견적제품을 입력해주세요
            </Highlight>
            </Heading>
            {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">4.</Text><Text display="inline-block" textDecoration="underline">LIST OF PRODUCTS</Text></Box> */}
                <FormControl p={{base:"0px", lg:"30px"}} pt="30px">
                    <FormControl background="#0765A3" color="#fff" pt="10x" pb="0px">
                        <HStack>
                            <FormControl w={{base: "5%", lg:"5%"}}>
                                    <FormLabel textAlign="center" mr="0" fontSize={{base: "10px", lg: "14px"}}>No</FormLabel>
                            </FormControl>
                            <FormControl  w={{base: "40%", lg:"45%"}}>
                                    <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Description</FormLabel>
                            </FormControl>
                            <FormControl w={{base: "10%", lg:"10%"}}>
                                    <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Qty</FormLabel>
                            </FormControl>
                            <FormControl w={{base: "20%", lg:"25%"}}>
                                    <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Unit Price</FormLabel>
                            </FormControl>
                            <FormControl w={{base: "25%", lg:"15%"}}>
                                    <FormLabel textAlign="center" fontSize={{base: "10px", lg: "14px"}}>Amount</FormLabel>
                            </FormControl>
                            <HStack>
                            <IconButton
                                aria-label="open menu"
                                variant="clear"
                            />
                            <IconButton
                                aria-label="open menu"
                                variant="clear"
                            />
                            </HStack>
                        </HStack>
                    </FormControl>

                    {fields.map((field,index)=>(
                    <FormControl key={field.id} mt="7px" mb="7px">
                        <HStack>
                        <FormControl w={{base: "5%", lg:"5%"}}>
                            <Text textAlign="center" fontSize={{base: "10px", lg: "12px"}}>{index+1}</Text>
                        </FormControl>
                        <FormControl w={{base: "40%", lg:"45%"}}>
                            <Input textAlign="center" variant='flushed'{...register(`quotation_items.${index}.description` as const, { required: true, })} type="text"  fontSize={{base: "10px", lg: "12px"}}/>
                        </FormControl>
                        <FormControl w={{base: "5%", lg:"10%"}}>
                            <Input textAlign="center" variant='flushed'{...register(`quotation_items.${index}.qty` as const, { required: true,valueAsNumber: true,})} type="number"  fontSize={{base: "10px", lg: "12px"}}/>
                        </FormControl>
                        <FormControl w={{base: "20%", lg:"25%"}}>
                            <HStack>

                            <Input textAlign="center" variant='flushed'{...register(`quotation_items.${index}.unit_price` as const, { required: true, valueAsNumber: true,})} type="number"  fontSize={{base: "10px", lg: "12px"}}/>
                            
                            <IconButton 
                                    aria-label="Divide 1.1"
                                    icon={<FaDivide />}
                                    onClick={() => {
                                        setValue(`quotation_items.${index}.unit_price`, getValues(`quotation_items.${index}.unit_price`) / 1.1);
                                    }}
                                    cursor="pointer"
                                    fontSize={{base: "12px", lg: "14px"}}
                                    color="blue.500"
                                    size={{base:"xs", lg:"xs"}}

                                >
                                    나누기 1.1
                                </IconButton>
                            </HStack>
                        </FormControl>
                        <FormControl w={{base: "25%", lg:"15%"}}>
                            <ConditionalInput {...{ control, index }} />
                            {<Amount control={control}/>}
                        </FormControl>
                        <HStack>
                        <IconButton
                            aria-label="open menu"
                            icon={<AiOutlinePlus />}
                            onClick={() =>
                                append({
                                    description: "",
                                    qty: 0,
                                    unit_price: 0,
                                    amount: 0
                                })
                            }
                            size={{base:"xs", lg:"md"}}
                            variant='outline'

                        />
                        <IconButton
                            aria-label="open menu"
                            icon={<AiOutlineMinus />}
                            onClick={() => remove(index)}
                            size={{base:"xs", lg:"md"}}
                            variant='outline'


                        />
                        </HStack>
                        </HStack>
                    </FormControl>
                    ))}
                    <Box display="flex" justifyContent="space-between"  mt="40px">
                        <Box></Box>
                        <Box>
                            <HStack borderTop="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="total_price"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Total Price:</b> $</Box><Box><TotalPrice control={control} /></Box></HStack>
                            <HStack borderTop="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="gst_total"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Gst:</b> $</Box><Box><Gst control={control} /></Box></HStack>
                            <HStack borderTop="1px solid #f0f0f0" borderBottom="1px solid #f0f0f0" pt="15px" pb="15px" justifyContent="flex-end" id="final_price"><Box w={{base: "100%", lg: "150px"}} textAlign="right"><b>Final Price:</b> $</Box><Box><FinalPrice control={control}/></Box></HStack>
                            </Box>
                        </Box>
                </FormControl>
                    <p>{errors.quotation_items?.root?.message}</p>
            
            </Box>
                {/* 
                ***
                5. Sales Agreement Special Conditions
                ***
                */}
                <Box mb={{base: "50px", lg: "100px"}}>

                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query='특이사항'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    3.  견적서 특이사항을 입력해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700" mt="50px"><Text display="inline-block" mr="20px">5.</Text><Text display="inline-block" textDecoration="underline">SPECIAL CONDITION</Text></Box> */}
                        <FormControl p={{base:"0px", lg:"30px"}} pt="40px">
                                <FormLabel fontSize={{base: "10px", lg:"14px"}}>Special Condition</FormLabel>
                                <Textarea {...register("special_condition", { required: true })}  />
                        </FormControl>
                </Box>
                {/* 
                ***
                6. Sales Agreement Terms & Conditions
                ***
                */}
                <Heading size={{base: "sm", lg: "md"}} lineHeight='tall' mt="50px">
                <Highlight
                    query={['Terms', 'Conditions']}
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'gray.100' }}
                >
                    4.  견적서에 맞는 Terms & Conditions을 선택해주세요
                </Highlight>
                </Heading>
                {/* <Box fontSize="2xl" fontWeight="700"><Text display="inline-block" mr="20px" mt="50px">6.</Text><Text display="inline-block" textDecoration="underline">TERMS & CONDITION</Text></Box> */}

                <Box>
                    <VStack spacing={15} as="form" onSubmit={handleSubmit(onSubmit)} mt={5}>
                    
                    {/* <FormControl>
                        <FormLabel>Quotation Category </FormLabel>
                        <Select {...register("kind", { required: true })}
                            placeholder="Choose a kind"
                        >
                            <option value="Implant">Implant</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Seminar">Seminar</option>
                        </Select>
                    </FormControl> */}



                    <p>{errors.quotation_items?.root?.message}</p>

                    
                    
                    <FormControl p="30px" pt="20px">
                            <Grid templateColumns={"1fr 1fr 1fr"} gap={5}>
                                {quotetncs?.map((quotetnc,index) => (
                                <Box key={quotetnc.id}>
                                    <Checkbox value={quotetnc.id}  {...register("quotetncs", { required: true })}>{quotetnc.name}</Checkbox>
                                </Box>
                                ))}
                            </Grid>
                    </FormControl>
                    {mutation.isError ? (
                        <Text color="red.500">Something went wrong</Text>
                    ) : null}
                    <Button
                    type="submit"
                    isLoading={mutation.isLoading}
                    colorScheme="facebook"
                    size="lg"
                    w={{base: "100%", lg: "200px"}}
                    >
                    [Draft] 견적서 생성
                    </Button>
                    </VStack>
                </Box>
            </Box>
        </ProtectedPage>
    )
}
